import React from "react";
import styled from "styled-components";
import Modal from "react-modal";
import { Typography } from "..";
import { Col, Row } from "react-grid-system";
import CloseLogo from "../../assets/img/close.svg";

Modal.setAppElement("#root");

const CloseButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: -10px;
  right: -5px;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

const DialogContent = styled.div`
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
`;

const Dialog = ({ isOpen, onClose, onEnter, title, children, size = "sm" }) => {
  const dialogSizes = {
    xs: "328px",
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
  };

  const dialogStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      // marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "12px",
      padding: "30px 5px",
      border: "none",
      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
      maxWidth: dialogSizes[size],
      // width: dialogSizes[size],
      width: "90%",
      overflowX: "hidden",
    },
    overlay: {
      zIndex: "99999999999999999",
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      contentLabel={title}
      style={dialogStyles}
      onRequestClose={onClose}
      closeTimeoutMS={250}
      onAfterOpen={onEnter}
    >
      <Row className="px-4">
        <Col>
          <Typography.Header2 reduceSizeMobile className="mt-0">
            {title}
          </Typography.Header2>
        </Col>
        <Col xs={2} sm={2} className="text-right">
          <CloseButton onClick={onClose}>
            <img src={CloseLogo} alt="Uždaryti" />
          </CloseButton>
        </Col>
      </Row>

      <DialogContent className="px-4">{children}</DialogContent>
    </Modal>
  );
};

export default Dialog;
