import styled from "styled-components";

const NavbarWrapper = styled.nav`
  margin-top: 15px;
  margin-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;

  @media (max-width: 768px) {
    padding-left: 5px;
    padding-right: 10px;
  }
`;

export default NavbarWrapper;
