import React from "react";
import { CompanyListAnimation } from "../../../components";
import { Typography } from "../../../ui";
import RequestHeader from "../components/RequestHeader";

const NoConfirmationsView = ({ request }) => {
  return (
    <>
      <RequestHeader request={request} />

      <div className="text-center mt-5">
        <Typography.Header2 reduceSizeMobile>
          Kreditoriai šiuo metu apdoroja Jūsų paraišką.
        </Typography.Header2>

        <Typography.Text grey>
          Pateiksime Jums palankiausius pasiūlymus ir galimą pasiskolinti
          mažiausią
          <br />
          bei didžiausią finansavimo sumą.
        </Typography.Text>
      </div>

      <CompanyListAnimation creditCompanies={request.availableConfirmations} />
    </>
  );
};

export default NoConfirmationsView;
