const lithuanianMunicipalities = [
  "Vilniaus m.",
  "Kauno m.",
  "Klaipėdos m.",
  "Šiaulių m.",
  "Panevėžio m.",
  "Alytaus m.",
  "",
  "Akmenės raj.",
  "Alytaus raj.",
  "Anykščių raj.",
  "Birštono sav.",
  "Biržų raj.",
  "Druskininkų sav.",
  "Elektrėnų sav.",
  "Ignalinos raj.",
  "Jonavos raj.",
  "Joniškio raj.",
  "Jurbarko raj.",
  "Kaišiadorių raj.",
  "Kalvarijos sav.",
  "Kauno raj.",
  "Kazlų Rūdos sav.",
  "Kėdainių raj.",
  "Kelmės raj.",
  "Klaipėdos raj.",
  "Kretingos raj.",
  "Kupiškio raj.",
  "Lazdijų raj.",
  "Marijampolės",
  "Mažeikių raj.",
  "Molėtų raj.",
  "Neringos sav.",
  "Pagėgių sav.",
  "Pakruojo raj.",
  "Palangos m.",
  "Panevėžio raj.",
  "Pasvalio raj.",
  "Plungės raj.",
  "Prienų raj.",
  "Radviliškio raj.",
  "Raseinių raj.",
  "Rietavo sav.",
  "Rokiškio raj.",
  "Skuodo raj.",
  "Šakių raj.",
  "Šalčininkų raj.",
  "Šiaulių raj.",
  "Šilalės raj.",
  "Šilutės raj.",
  "Širvintų raj.",
  "Švenčionių raj.",
  "Tauragės raj.",
  "Telšių raj.",
  "Trakų raj.",
  "Ukmergės raj.",
  "Utenos raj.",
  "Varėnos raj.",
  "Vilkaviškio raj.",
  "Vilniaus raj.",
  "Visagino sav.",
  "Zarasų raj.",
];

export default lithuanianMunicipalities;
