import React from "react";
import styled from "styled-components";
import { Row, Col } from "react-grid-system";
import { Typography } from "../../ui";
import ForwardLogo from "../../assets/img/forward.svg";

const IdentityConfirmationMethodWrapper = styled.div`
  width: 100%;
  padding-top: 24px;
  padding-bottom: 24px;
  padding: 24px 0px;
  border-bottom: 1px solid #dadbe0;
  cursor: pointer;

  &:hover {
    background: #f8f8f8;
  }
`;

const IdentityConfirmationMethod = ({ title, description, logo, onClick }) => {
  const Logo = require(`../../assets/img/methods/${logo}.png`);

  return (
    <IdentityConfirmationMethodWrapper onClick={onClick}>
      <Row gutterWidth={20}>
        <Col xs={4} sm={3} md={2} className="my-auto">
          <div>
            <img src={Logo} className="img-fluid" alt={title} />
          </div>
        </Col>
        <Col xs={6.5} sm={8} md={9} className="my-auto">
          <Typography.Text size="lead" weight={500} className="mt-0 mb-2">
            {title}
          </Typography.Text>

          <Typography.InlineText grey size="small">
            {description}
          </Typography.InlineText>
        </Col>
        <Col xs={1.5} sm={1} md={1} className="text-right my-auto pl-0">
          <img src={ForwardLogo} className="img-fluid" alt="Pasirinkti" />
        </Col>
      </Row>
    </IdentityConfirmationMethodWrapper>
  );
};

export default IdentityConfirmationMethod;
