import React from "react";
import { Row, Col } from "react-grid-system";
import {
  Button,
  ButtonAdornment,
  Dialog,
  PaddingWrapper,
  Separator,
  Typography,
} from "../../../ui";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import SuccessLogo from "../../../assets/img/success.svg";
import { useHistory } from "react-router-dom";
import { OfferApi } from "../../../api";
import { toast } from "react-toastify";

const OfferAcceptDialog = ({
  isOpen,
  onClose,
  creditConfirmation,
  creditCompany,
  hideLabel = false,
  hiddenLabel = "",
}) => {
  const [isOfferAccepted, setIsOfferAccepted] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const history = useHistory();

  const handleAccept = async () => {
    const creditConfirmationId = creditConfirmation._id;
    setIsLoading(true);

    try {
      await OfferApi.acceptOffer(creditConfirmationId);
    } catch (error) {
      setIsLoading(false);

      return toast.error(error);
    }

    setIsLoading(false);
    setIsOfferAccepted(true);
  };

  const handleRefresh = () => {
    if (creditCompany.agreementSignUrl) {
      window.location.href = creditCompany.agreementSignUrl;
    } else {
      history.go(0);
    }
  };

  return (
    <Dialog
      isOpen={isOpen}
      title={isOfferAccepted ? null : "Paskolos detalės"}
      onClose={isOfferAccepted ? handleRefresh : onClose}
    >
      {isOfferAccepted ? (
        <>
          <div className="mt-2 text-center">
            <img src={SuccessLogo} alt="Sėkmingas veiksmas" />
          </div>

          <Typography.Header2 center>
            Jūsų pasiūlymas sėkmingai <br /> patvirtintas
          </Typography.Header2>

          <Typography.Text greyDark center>
            {creditCompany.agreementSignUrl
              ? "Paspauskite apačioje esantį mygtuką ir būsite nukreipti pasirašyti paskolos sutartį."
              : "Netrukus su Jumis susisieks pasiūlymą pateikusi kredito įstaiga."}
          </Typography.Text>

          <PaddingWrapper>
            <Button onClick={handleRefresh} fullWidth>
              Supratau
            </Button>
          </PaddingWrapper>
        </>
      ) : (
        <>
          <Typography.Text greyDark className="mt-0">
            Patvirtinkite, kad paskolos duomenys yra teisingi.
          </Typography.Text>

          <PaddingWrapper>
            <Separator />
          </PaddingWrapper>

          <Row className="mt-3">
            {/* <Col xs={6}>
              <Typography.Text className="mt-0" greyDark>
                Kreditorius
              </Typography.Text>
            </Col>

            <Col xs={6} className="text-right">
              <Typography.Text className="mt-0" weight={500}>
                {!hideLabel
                  ? creditConfirmation.creditCompany.name
                  : hiddenLabel}
              </Typography.Text>
            </Col> */}

            <Col xs={6}>
              <Typography.Text className="mt-0" greyDark>
                Pasiūlymo suma
              </Typography.Text>
            </Col>

            <Col xs={6} className="text-right">
              <Typography.Text className="mt-0" weight={500}>
                {creditConfirmation.creditOfferFrom} €
              </Typography.Text>
            </Col>

            {creditConfirmation.creditOfferFrom !==
              creditConfirmation.creditOfferTo && (
              <>
                <Col xs={6}>
                  <Typography.Text className="mt-0" greyDark>
                    Pasiūlymo suma iki
                  </Typography.Text>
                </Col>

                <Col xs={6} className="text-right">
                  <Typography.Text className="mt-0" weight={500}>
                    {creditConfirmation.creditOfferTo} €
                  </Typography.Text>
                </Col>
              </>
            )}

            <Col xs={6}>
              <Typography.Text className="mt-0" greyDark>
                Terminas
              </Typography.Text>
            </Col>

            <Col xs={6} className="text-right">
              <Typography.Text className="mt-0" weight={500}>
                {creditConfirmation.creditLength} mėn.
              </Typography.Text>
            </Col>

            <Col xs={6}>
              <Typography.Text className="mt-0" greyDark>
                Palūkanos
              </Typography.Text>
            </Col>

            <Col xs={6} className="text-right">
              <Typography.Text className="mt-0" weight={500}>
                {creditConfirmation.interestRate}%
              </Typography.Text>
            </Col>

            <Col xs={6}>
              <Typography.Text className="mt-0" greyDark>
                BVKKMN
              </Typography.Text>
            </Col>

            <Col xs={6} className="text-right">
              <Typography.Text className="mt-0" weight={500}>
                {creditConfirmation.BVKKMN}%
              </Typography.Text>
            </Col>

            <Col xs={6}>
              <Typography.Text className="mt-0" greyDark>
                Įmoka
              </Typography.Text>
            </Col>

            <Col xs={6} className="text-right">
              <Typography.Text className="mt-0" weight={500}>
                {creditConfirmation.monthlyPayment} € / mėn.
              </Typography.Text>
            </Col>

            {creditConfirmation.fee ? (
              <>
                <Col xs={6}>
                  <Typography.Text className="mt-0" greyDark>
                    Sutarties mokestis
                  </Typography.Text>
                </Col>

                <Col xs={6} className="text-right">
                  <Typography.Text className="mt-0" weight={500}>
                    {creditConfirmation.fee} €
                  </Typography.Text>
                </Col>
              </>
            ) : (
              ""
            )}

            {creditConfirmation.entranceFee ? (
              <>
                <Col xs={6}>
                  <Typography.Text className="mt-0" greyDark>
                    Stojimo mokestis
                  </Typography.Text>
                </Col>

                <Col xs={6} className="text-right">
                  <Typography.Text className="mt-0" weight={500}>
                    {creditConfirmation.entranceFee} €
                  </Typography.Text>
                </Col>
              </>
            ) : (
              ""
            )}

            {creditConfirmation.share ? (
              <>
                <Col xs={6}>
                  <Typography.Text className="mt-0" greyDark>
                    Pajinis įnašas
                  </Typography.Text>
                </Col>

                <Col xs={6} className="text-right">
                  <Typography.Text className="mt-0" weight={500}>
                    {creditConfirmation.share} €
                  </Typography.Text>
                </Col>
              </>
            ) : (
              ""
            )}
          </Row>

          <PaddingWrapper>
            <Button onClick={handleAccept} disabled={isLoading} fullWidth>
              Patvirtinti ir tęsti
              <ButtonAdornment end>
                <HiOutlineArrowNarrowRight />
              </ButtonAdornment>
            </Button>

            <Button link fullWidth onClick={onClose} className="mt-2">
              Grįžti į pasiūlymų sąrašą
            </Button>
          </PaddingWrapper>
        </>
      )}
    </Dialog>
  );
};

export default OfferAcceptDialog;
