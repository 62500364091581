import React from "react";
import { Col, Row, Visible } from "react-grid-system";
import { UserMenu } from "../../../components";
import NavbarLogo from "../../../components/Navbar/NavbarLogo";
import Logo from "../../../assets/img/logo.svg";
import { Href } from "../../../ui";
import { Link } from "react-router-dom";
import MobileMenu from "./MobileMenu";

const Navbar = ({ title, activeRoute }) => {
  return (
    <Row gutterWidth={0}>
      <Col>
        <Visible xs sm md lg>
          <Href to="/" as={Link}>
            <NavbarLogo src={Logo} />
          </Href>
        </Visible>

        <Visible xl xxl>
          {title}
        </Visible>
      </Col>
      <Col
        className="text-right"
        style={{
          marginTop: "-10px",
        }}
      >
        <UserMenu activeRoute={activeRoute} />
        <MobileMenu activeRoute={activeRoute} />
      </Col>
    </Row>
  );
};

export default Navbar;
