import React from "react";
import { ReactComponent as FastLogo } from "../../../../assets/img/fast.svg";
import { Typography, PaddingWrapper } from "../../../../ui";

const Fast = () => (
  <>
    <div>
      <FastLogo />
    </div>

    <Typography.Header1 white>Čia ir dabar</Typography.Header1>

    <PaddingWrapper size="small">
      <Typography.Text className="mt-0 mb-0" white>
        Paskolos pasiūlymą gaukite per valandą.
      </Typography.Text>
    </PaddingWrapper>
  </>
);

export default Fast;
