import React from "react";
import { Row, Col } from "react-grid-system";
import { AuthenticationLayout } from "../../layouts";
import {
  Button,
  ButtonAdornment,
  Input,
  PaddingWrapper,
  Typography,
} from "../../ui";
import { Formik, Field, Form } from "formik";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import errors from "../../locale/lt_LT/errors";
import * as yup from "yup";
import { UserApi } from "../../api";
import { toast } from "react-toastify";

const validationSchema = yup.object().shape({
  email: yup
    .string(errors.invalidFormat)
    .email(errors.invalidFormat)
    .required(errors.required),
});

const ForgotPage = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSubmitted, setIsSubmitted] = React.useState(false);

  const handleSubmit = async (values) => {
    setIsLoading(true);

    try {
      await UserApi.postResetToken(values.email);
    } catch (error) {
      setIsLoading(false);
      return toast.error(error);
    }

    setIsLoading(false);
    setIsSubmitted(true);
  };

  return (
    <AuthenticationLayout>
      <Row gutterWidth={0}>
        <Col xs={12} sm={12} md={10} lg={8} xl={7} xxl={6} className="mx-auto">
          <Typography.Header1 reduceSizeMobile>
            Slaptažodžio atstatymas
          </Typography.Header1>

          {isSubmitted ? (
            <>
              <Typography.Text grey justify weight={500} className="mt-0 mb-4">
                Žinutė buvo išsiųsta į Jūsų el. paštą.
              </Typography.Text>
            </>
          ) : (
            <>
              <Typography.Text grey justify weight={500} className="mt-0 mb-4">
                Įveskite savo el. pašto adresą, kurį naudojote regitruojantis į
                Credit King.
              </Typography.Text>

              <Formik
                initialValues={{
                  email: "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched }) => (
                  <Form>
                    <PaddingWrapper className="mt-0">
                      <Field
                        label="Elektroninis paštas"
                        id="email"
                        name="email"
                        type="email"
                        as={Input}
                        fullWidth
                        error={errors.email && touched.email}
                        errorText={touched.email && errors.email}
                        required
                      />
                    </PaddingWrapper>

                    <PaddingWrapper>
                      <Button type="submit" disabled={isLoading} fullWidth>
                        <ButtonAdornment end>
                          <HiOutlineArrowNarrowRight />
                        </ButtonAdornment>
                        Atkurti slaptažodį
                      </Button>
                    </PaddingWrapper>
                  </Form>
                )}
              </Formik>
            </>
          )}
        </Col>
      </Row>
    </AuthenticationLayout>
  );
};

export default ForgotPage;
