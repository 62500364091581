import React from "react";
import { Col, Row } from "react-grid-system";
import { UserContext } from "../../context/UserContext";
import { DashboardLayout } from "../../layouts";
import {
  Card,
  Input,
  Typography,
  PaddingWrapper,
  Checkbox,
  Button,
} from "../../ui";
import { Formik, Field, Form } from "formik";
import * as yup from "yup";
import errors from "../../locale/lt_LT/errors";
import { UserApi } from "../../api";
import { toast } from "react-toastify";

const validationSchema = yup.object().shape({
  firstName: yup.string(errors.invalidFormat).required(errors.required),
  lastName: yup.string(errors.invalidFormat).required(errors.required),
  email: yup
    .string(errors.invalidFormat)
    .email(errors.invalidFormat)
    .required(errors.required),
  contactNumber: yup
    .string(errors.invalidFormat)
    .matches(/^[0-9]+$/, errors.invalidFormat)
    .min(8, errors.invalidFormat)
    .max(8, errors.invalidFormat)
    .required(errors.required),
  currentPassword: yup.string(errors.invalidFormat).when("password", {
    is: (password) => password && password.length > 0,
    then: yup.string(errors.invalidFormat).required(errors.required),
  }),
  password: yup.string(errors.invalidFormat),
});

const SettingsPage = () => {
  const userContext = React.useContext(UserContext);
  const { user } = userContext;

  const [isLoading, setIsLoading] = React.useState(false);

  const handleSubmit = async (values) => {
    const userId = user._id;
    setIsLoading(true);

    try {
      await UserApi.patchUser(userId, values);
      await userContext.getUser();
    } catch (error) {
      setIsLoading(false);
      return toast.error(error);
    }

    toast.dark("Paskyra sėkmingai atnaujinta");
    setIsLoading(false);
  };

  return (
    <DashboardLayout
      title={
        <>
          <Typography.Header1 reduceSizeMobile className="mt-0">
            Paskyros nustatymai
          </Typography.Header1>
        </>
      }
      activeRoute="settings"
    >
      <Row gutterWidth={0}>
        <Col xs={12} sm={10} md={8} lg={6}>
          <Card className="p-4">
            <Formik
              initialValues={{
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                contactNumber: user.contactNumber,
                currentPassword: "",
                password: "",
                marketingAccept: user.marketingAccept,
              }}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {({ values, errors, touched }) => (
                <Form>
                  <Typography.Text className="m-0 mb-2">
                    Kontaktiniai duomenys
                  </Typography.Text>

                  <PaddingWrapper>
                    <Field
                      label="Vardas"
                      id="firstName"
                      name="firstName"
                      type="text"
                      as={Input}
                      fullWidth
                      error={errors.firstName && touched.firstName}
                      errorText={touched.firstName && errors.firstName}
                      required
                    />
                  </PaddingWrapper>

                  <PaddingWrapper>
                    <Field
                      label="Pavardė"
                      id="lastName"
                      name="lastName"
                      type="text"
                      as={Input}
                      fullWidth
                      error={errors.lastName && touched.lastName}
                      errorText={touched.lastName && errors.lastName}
                      required
                    />
                  </PaddingWrapper>

                  <PaddingWrapper>
                    <Field
                      label="Elektroninis paštas"
                      id="email"
                      name="email"
                      type="email"
                      as={Input}
                      fullWidth
                      error={errors.email && touched.email}
                      errorText={touched.email && errors.email}
                      required
                    />
                  </PaddingWrapper>

                  <PaddingWrapper>
                    <Field
                      label="Telefono numeris"
                      id="contactNumber"
                      name="contactNumber"
                      type="text"
                      as={Input}
                      fullWidth
                      error={errors.contactNumber && touched.contactNumber}
                      errorText={touched.contactNumber && errors.contactNumber}
                      startAdornment="+370"
                      required
                    />
                  </PaddingWrapper>

                  <Typography.Text className="mb-2">
                    Slaptažodžio keitimas
                  </Typography.Text>

                  <PaddingWrapper>
                    <Field
                      label="Dabartinis slaptažodis"
                      id="currentPassword"
                      name="currentPassword"
                      type="password"
                      as={Input}
                      fullWidth
                      error={errors.currentPassword && touched.currentPassword}
                      errorText={
                        touched.currentPassword && errors.currentPassword
                      }
                    />
                  </PaddingWrapper>

                  <PaddingWrapper>
                    <Field
                      label="Naujas slaptažodis"
                      id="password"
                      name="password"
                      type="password"
                      as={Input}
                      fullWidth
                      error={errors.password && touched.password}
                      errorText={touched.password && errors.password}
                      required={
                        values.currentPassword &&
                        values.currentPassword.length > 0
                      }
                    />
                  </PaddingWrapper>

                  <PaddingWrapper>
                    <Field
                      type="checkbox"
                      id="marketingAccept"
                      name="marketingAccept"
                      as={Checkbox}
                      label="Sutinku, kad mano duomenys būtų tvarkomi tiesioginės rinkodaros tikslais"
                    />
                  </PaddingWrapper>

                  <PaddingWrapper>
                    <Button disabled={isLoading} type="submit" fullWidth>
                      Išsaugoti pakeitimus
                    </Button>
                  </PaddingWrapper>
                </Form>
              )}
            </Formik>
          </Card>
        </Col>
      </Row>
    </DashboardLayout>
  );
};

export default SettingsPage;
