import React from "react";
import { Card, Typography } from "../../../ui";
import { ReactComponent as DocumentLogo } from "../../../assets/img/document.svg";
import { ReactComponent as DeleteLogo } from "../../../assets/img/delete.svg";
import { Col, Row } from "react-grid-system";
import DocumentDeleteDialog from "./DocumentDeleteDialog";

const UploadedDocument = ({ document, className = "" }) => {
  const [
    isDocumentDeleteModalOpen,
    setIsDocumentDeleteModalOpen,
  ] = React.useState(false);

  return (
    <div className={className}>
      <Card className="mb-3">
        <div className="py-3 px-4">
          <Row gutterWidth={10}>
            <Col>
              <table
                style={{
                  width: "100%",
                }}
              >
                <tr>
                  <td
                    valign="top"
                    style={{
                      width: "30px",
                    }}
                  >
                    <DocumentLogo
                      style={{
                        verticalAlign: "-10px",
                      }}
                    />
                  </td>
                  <td>
                    <Typography.InlineText
                      size="lead"
                      weight={500}
                      className="mt-0"
                    >
                      {document.type}
                    </Typography.InlineText>

                    <div>
                      <Typography.InlineText greyDark size="small">
                        Dokumentų kiekis: {document.files.length}
                      </Typography.InlineText>
                    </div>
                  </td>
                </tr>
              </table>

              {/* <span
                class="pr-3"
                style={{
                  display: "inline-block",
                  verticalAlign: "17px",
                }}
              >
                <DocumentLogo />
              </span>
              <span
                style={{
                  display: "inline-block",
                }}
              >
                <Typography.InlineText
                  size="lead"
                  weight={500}
                  className="mt-0"
                >
                  {document.type}
                </Typography.InlineText>

                <div>
                  <Typography.InlineText greyDark size="small">
                    Dokumentų kiekis: {document.files.length} · Įkelta
                    2020-11-27
                  </Typography.InlineText>
                </div>
              </span> */}
            </Col>
            <Col xs={1} className="text-center my-auto">
              <DeleteLogo
                cursor="pointer"
                onClick={() => setIsDocumentDeleteModalOpen(true)}
              />
            </Col>
          </Row>
        </div>
      </Card>

      <DocumentDeleteDialog
        isOpen={isDocumentDeleteModalOpen}
        onClose={() => setIsDocumentDeleteModalOpen(false)}
        document={document}
      />
    </div>
  );
};

export default UploadedDocument;
