import React from "react";
import IdentityConfirmationMethod from "./IdentityConfirmationMethod";

const IdentityConfirmationMethods = ({ confirmationMethods = [] }) => (
  <div className="w-100">
    {confirmationMethods.map((method, index) => (
      <IdentityConfirmationMethod {...method} key={index} />
    ))}
  </div>
);

export default IdentityConfirmationMethods;
