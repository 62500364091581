import React from "react";
import { Container } from "react-grid-system";
import { Navbar } from "../../components";

const ApplicationLayout = ({ children }) => (
  <>
    <Navbar />
    <Container>{children}</Container>
  </>
);

export default ApplicationLayout;
