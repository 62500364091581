import styled, { css } from "styled-components";
import textSizeSwitcher from "../switchers/textSizeSwitcher";
import theme from "../../config/theme";

const TypographyBase = css`
  color: ${theme.colors.black};

  ${({ justify }) =>
    justify &&
    css`
      text-align: justify;
    `}

  ${({ center }) =>
    center &&
    css`
      text-align: center;
    `}

  ${({ right }) =>
    right &&
    css`
      text-align: right;
    `}

  ${({ grey }) =>
    grey &&
    css`
      color: ${theme.colors.grey};
    `}

  ${({ greyDark }) =>
    greyDark &&
    css`
      color: #808188;
    `}

  ${({ purple }) =>
    purple &&
    css`
      color: #4575f2;
    `}

  ${({ red }) =>
    red &&
    css`
      color: #d02b2b;
    `}

  ${({ white }) =>
    white &&
    css`
      color: #ffffff;
    `}

  ${({ weight }) =>
    weight &&
    css`
      font-weight: ${weight};
    `}


  ${({ inline }) =>
    inline &&
    css`
      display: inline-block;
    `}

  ${({ block }) =>
    block &&
    css`
      display: block;
    `}
`;

const HeaderBase = css`
  font-weight: 500;
`;

const TextBase = css`
  ${TypographyBase}
  ${({ size }) => size && textSizeSwitcher(size)}
`;

const Text = styled.p`
  ${TextBase}
`;

const InlineText = styled.span`
  ${TextBase}
`;

const Header1 = styled.h1`
  ${TypographyBase};
  ${HeaderBase};
  font-size: 32px;
  line-height: 48px;
  letter-spacing: 0%;

  ${({ reduceSizeMobile }) =>
    reduceSizeMobile &&
    css`
      @media (max-width: 576px) {
        font-size: 24px;
        line-height: 36px;
      }
    `}
`;

const Header2 = styled.h2`
  ${TypographyBase};
  ${HeaderBase};
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0%;

  ${({ reduceSizeMobile }) =>
    reduceSizeMobile &&
    css`
      @media (max-width: 576px) {
        font-size: 20px;
        line-height: 30px;
      }
    `}
`;

const Header3 = styled.h3`
  ${TypographyBase};
  ${HeaderBase};
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0%;

  ${({ reduceSizeMobile }) =>
    reduceSizeMobile &&
    css`
      @media (max-width: 576px) {
        font-size: 16px;
        line-height: 24px;
      }
    `}
`;

export default {
  Text,
  InlineText,
  Header1,
  Header2,
  Header3,
};
