const fromGoogleToSystem = {
  "Vilniaus m. sav.": "Vilniaus m.",
  "Kauno m. sav.": "Kauno m.",
  "Klaipėdos m. sav.": "Klaipėdos m.",
  "Šiaulių m. sav.": "Šiaulių m.",
  "Panevėžio m. sav.": "Panevėžio m.",
  "Alytaus m. sav.": "Alytaus m.",
  "Akmenės r. sav.": "Akmenės raj.",
  "Alytaus r. sav.": "Alytaus raj.",
  "Anykščių r. sav.": "Anykščių raj.",
  "Birštono sav.": "Birštono sav.",
  "Biržų r. sav.": "Biržų raj.",
  "Druskininkų sav.": "Druskininkų sav.",
  "Elektrėnų sav.": "Elektrėnų sav.",
  "Ignalinos r. sav.": "Ignalinos raj.",
  "Jonavos r. sav.": "Jonavos raj.",
  "Joniškio r. sav.": "Joniškio raj.",
  "Jurbarko r. sav.": "Jurbarko raj.",
  "Kaišiadorių r. sav.": "Kaišiadorių raj.",
  "Kalvarijos sav.": "Kalvarijos sav.",
  "Kauno r. sav.": "Kauno raj.",
  "Kazlų Rūdos sav.": "Kazlų Rūdos sav.",
  "Kėdainių r. sav.": "Kėdainių raj.",
  "Kelmės r. sav.": "Kelmės raj.",
  "Klaipėdos r. sav.": "Klaipėdos raj.",
  "Kretingos r. sav.": "Kretingos raj.",
  "Kupiškio r. sav.": "Kupiškio raj.",
  "Lazdijų r. sav.": "Lazdijų raj.",
  "Marijampolės sav.": "Marijampolės",
  "Mažeikių r. sav.": "Mažeikių raj.",
  "Molėtų r. sav.": "Molėtų raj.",
  "Neringos sav.": "Neringos sav.",
  "Pagėgių sav.": "Pagėgių sav.",
  "Pakruojo r. sav.": "Pakruojo raj.",
  "Palangos m. sav.": "Palangos m.",
  "Panevėžio r. sav.": "Panevėžio raj.",
  "Pasvalio r. sav.": "Pasvalio raj.",
  "Plungės r. sav.": "Plungės raj.",
  "Prienų r. sav.": "Prienų raj.",
  "Radviliškio r. sav.": "Radviliškio raj.",
  "Raseinių r. sav.": "Raseinių raj.",
  "Rietavo sav.": "Rietavo sav.",
  "Rokiškio r. sav.": "Rokiškio raj.",
  "Skuodo r. sav.": "Skuodo raj.",
  "Šakių r. sav.": "Šakių raj.",
  "Šalčininkų r. sav.": "Šalčininkų raj.",
  "Šiaulių r. sav.": "Šiaulių raj.",
  "Šilalės r. sav.": "Šilalės raj.",
  "Šilutės r. sav.": "Šilutės raj.",
  "Širvintų r. sav.": "Širvintų raj.",
  "Švenčionių r. sav.": "Švenčionių raj.",
  "Tauragės r. sav.": "Tauragės raj.",
  "Telšių r. sav.": "Telšių raj.",
  "Trakų r. sav.": "Trakų raj.",
  "Ukmergės r. sav.": "Ukmergės raj.",
  "Utenos r. sav.": "Utenos raj.",
  "Varėnos r. sav.": "Varėnos raj.",
  "Vilkaviškio r. sav.": "Vilkaviškio raj.",
  "Vilniaus r. sav.": "Vilniaus raj.",
  "Visagino sav.": "Visagino sav.",
  "Zarasų r. sav.": "Zarasų raj.",
};

const convertGoogleLocation = {
  fromGoogleLocation(location) {
    return fromGoogleToSystem[location] || null;
  },
};

export default convertGoogleLocation;
