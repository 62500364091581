import axios from "axios";
import errors from "../locale/lt_LT/errors";

const IdentityApi = {
  createNeopayToken: async (requestConfirmationId, bank) => {
    try {
      var request = await axios.post(
        process.env.REACT_APP_API +
          "/api/requestConfirmations/" +
          requestConfirmationId +
          "/neopay/identification",
        {
          bank,
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
    } catch (error) {
      const message = error.response.data.error;
      return Promise.reject(errors[message] || errors.default);
    }

    return request.data;
  },
  createSmartidSession: async (requestConfirmationId) => {
    try {
      var request = await axios.post(
        process.env.REACT_APP_API +
          "/api/requestConfirmations/" +
          requestConfirmationId +
          "/smartid/session",
        {},
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
    } catch (error) {
      const message = error.response.data.error;
      return Promise.reject(message || errors.default);
    }

    const { data } = request;

    if (!data || !data.code || !data.sessionId) {
      return Promise.reject(errors.default);
    }

    return Promise.resolve(data);
  },
  createSmartIdAuthentication: async (requestConfirmationId, sessionId) => {
    try {
      await axios.post(
        process.env.REACT_APP_API +
          "/api/requestConfirmations/" +
          requestConfirmationId +
          "/smartid/authentication",
        {
          sessionId,
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
    } catch (error) {
      const message = error.response.data.error;
      return Promise.reject(message || errors.default);
    }

    return Promise.resolve();
  },
};

export default IdentityApi;
