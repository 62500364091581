import axios from "axios";
import errors from "../locale/lt_LT/errors";

const RequestApi = {
  submitRequest: async (requestObject) => {
    try {
      var request = await axios.post(
        process.env.REACT_APP_API + "/api/requests",
        requestObject,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
    } catch (error) {
      const message = error.response.data.error;
      return Promise.reject(errors[message] || errors.default);
    }

    return request.data;
  },
  getRequest: async (id) => {
    if (!id) {
      return Promise.reject(errors.default);
    }

    try {
      var request = await axios.get(
        process.env.REACT_APP_API + "/api/requests/" + id,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
    } catch (error) {
      return Promise.reject(errors.default);
    }

    if (!Array.isArray(request.data)) {
      return Promise.reject(errors.default);
    }

    return request.data[0];
  },
  getRequests: async () => {
    try {
      var request = await axios.get(
        process.env.REACT_APP_API + "/api/requests/",
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
    } catch (error) {
      return Promise.reject(errors.default);
    }

    const requests = request.data;

    if (!Array.isArray(requests)) {
      return Promise.reject(errors.default);
    }

    return requests;
  },
  postPotentialRequest: async (query) => {
    try {
      var request = await axios.post(
        process.env.REACT_APP_API + "/api/potentialRequests",
        query
      );
    } catch (error) {
      const message = error.response.data.error;
      return Promise.reject(errors[message] || errors.default);
    }

    return request.data;
  },
  patchExchangeInfo: async (id, exchangeData) => {
    if (!id || !exchangeData) {
      return Promise.reject(errors.default);
    }

    try {
      var request = await axios.patch(
        process.env.REACT_APP_API + "/api/requests/" + id + "/exchangeInfo",
        exchangeData,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
    } catch (error) {
      const message = error.response.data.error;
      return Promise.reject(errors[message] || errors.default);
    }

    return request.data;
  },
  patchPartnerInfo: async (id, partnerData) => {
    if (!id || !partnerData) {
      return Promise.reject(errors.default);
    }

    try {
      var request = await axios.patch(
        process.env.REACT_APP_API + "/api/requests/" + id + "/partner",
        partnerData,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
    } catch (error) {
      const message = error.response.data.error;
      return Promise.reject(errors[message] || errors.default);
    }

    return request.data;
  },
};

export default RequestApi;
