import { css } from "styled-components";

const textSizeSwitcher = (size = "base") => {
  const sizes = {
    micro: {
      size: 12,
      spacing: 2,
      height: 16,
    },
    small: {
      size: 14,
      spacing: 2,
      height: 20,
    },
    base: {
      size: 16,
      spacing: 1,
      height: 24,
    },
    lead: {
      size: 18,
      spacing: 1,
      height: 28,
    },
  };

  return css`
    font-size: ${sizes[size].size}px;
    line-height: ${sizes[size].height}px;
    letter-spacing: ${sizes[size].spacing}%;
  `;
};

export default textSizeSwitcher;
