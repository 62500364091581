import React from "react";
import styled from "styled-components";

const StyledCreditCompanyLogo = styled.img`
  max-height: 40px;
  max-width: 120px;
  width: auto;
  height: auto;
`;

const getCreditCompanyLogo = (creditCompany) => {
  try {
    return require(`../../assets/img/companies/${creditCompany.slug}.png`);
  } catch (error) {
    return (
      "http://placehold.jp/d4d4d4/003366/300x100.png?text=" + creditCompany.slug
    );
  }
};

const CreditCompanyLogo = ({ creditCompany }) => (
  <StyledCreditCompanyLogo
    src={getCreditCompanyLogo(creditCompany)}
    alt={creditCompany.name + " logotipas"}
    className="credit-company-logo"
  />
);

export default CreditCompanyLogo;
