import axios from "axios";

const FileApi = {
  upload: async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios({
        method: "POST",
        url: process.env.REACT_APP_API + "/api/files",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (!response || !response.data || !response.data.path) {
        return Promise.reject();
      }

      var { data } = response;

      if (data.error) {
        return Promise.reject();
      }
    } catch (error) {
      return Promise.reject(error);
    }

    return data;
  },
};

export default FileApi;
