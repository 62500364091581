import colors from "./colors";

const theme = {
  colors: colors,
  fonts: {
    size: 16,
    family: "Poppins, sans-serif",
  },
  spacing: {
    marginBase: 6,
  },
};

export default theme;
