import React from "react";
import { UserApi } from "../api";

const UserContext = React.createContext();
const { Provider, Consumer } = UserContext;

class UserContextProvider extends React.Component {
  state = {
    isAuthenticated: false,
    user: null,
    isUserDataFetched: false,

    setContext: (values) => {
      return new Promise((resolve, reject) => {
        this.setState(values, () => resolve(this.state));
      });
    },
  };

  componentDidMount() {
    this.getUser();

    this.setState({
      getUser: this.getUser,
    });
  }

  getUser = () => {
    const accessToken = localStorage.getItem("token");

    if (!accessToken) {
      this.setState({ isUserDataFetched: true });
      return;
    }

    return UserApi.getUser()
      .then((user) => {
        if (!user) {
          throw new Error();
        }

        this.setState({
          isAuthenticated: true,
          user,
        });
      })
      .catch(() => {
        localStorage.removeItem("token");
      })
      .finally(() => {
        this.setState({ isUserDataFetched: true });
      });
  };

  render() {
    const { children } = this.props;

    if (!this.state.isUserDataFetched) {
      return "";
    }

    return <Provider value={this.state}>{children}</Provider>;
  }
}

export { Provider, Consumer, UserContext, UserContextProvider };
