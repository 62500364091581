import React from "react";
import { Row, Col } from "react-grid-system";
import {
  PaddingWrapper,
  Typography,
  Input,
  Button,
  ButtonAdornment,
  Alert,
  Href,
} from "../../ui";
import { AuthenticationLayout } from "../../layouts";
import { UserApi } from "../../api";
import { Formik, Field, Form } from "formik";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { IoLogoGoogle } from "react-icons/io";
import { SiFacebook } from "react-icons/si";
import { UserContext } from "../../context/UserContext";
import errors from "../../locale/lt_LT/errors";
import { Link } from "react-router-dom";
import queryString from "query-string";

class LoginPage extends React.Component {
  state = {
    isLoading: false,
    error: null,
  };

  handleSubmit = async (values) => {
    this.setState({
      isLoading: true,
      error: null,
    });

    try {
      const token = await UserApi.login(values.email, values.password);
      localStorage.setItem("token", token);

      var user = await UserApi.getUser();

      if (!user) {
        throw errors.default;
      }

      await this.context.setContext({
        isAuthenticated: true,
        user,
      });
    } catch (error) {
      this.setState({ error });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  componentDidMount() {
    // Parse social medial auth params
    const parsedQueryString = queryString.parse(window.location.search);

    const authStatus = parsedQueryString.authStatus || "";
    const token = parsedQueryString.token || "";

    if (authStatus === "success" && token) {
      localStorage.setItem("token", token);
      window.location.href = "/";
    }
  }

  render() {
    return (
      <AuthenticationLayout>
        <Row gutterWidth={0}>
          <Col
            xs={12}
            sm={12}
            md={10}
            lg={8}
            xl={7}
            xxl={6}
            className="mx-auto"
          >
            {this.state.error && <Alert danger>{this.state.error}</Alert>}

            <Typography.Header1 reduceSizeMobile>
              Prisijunkite prie Credit King
            </Typography.Header1>

            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              onSubmit={this.handleSubmit}
            >
              <Form>
                <PaddingWrapper className="mt-0">
                  <Field
                    label="Elektroninis paštas"
                    id="email"
                    name="email"
                    type="email"
                    as={Input}
                    fullWidth
                    required
                  />
                </PaddingWrapper>

                <PaddingWrapper>
                  <Field
                    label="Slaptažodis"
                    id="password"
                    name="password"
                    type="password"
                    as={Input}
                    fullWidth
                    required
                  />

                  <div className="text-right mt-1">
                    <Typography.InlineText weight={500} size="small">
                      <Href as={Link} to="/forgot">
                        Pamiršai slaptažodį?
                      </Href>
                    </Typography.InlineText>
                  </div>
                </PaddingWrapper>

                <PaddingWrapper>
                  <Button
                    disabled={this.state.isLoading}
                    type="submit"
                    fullWidth
                  >
                    <ButtonAdornment end>
                      <HiOutlineArrowNarrowRight />
                    </ButtonAdornment>
                    Prisijungti
                  </Button>
                </PaddingWrapper>
              </Form>
            </Formik>

            <PaddingWrapper className="text-center">
              <Typography.InlineText grey>
                Taip pat galite prisijungti per socialinius tinklus
              </Typography.InlineText>
            </PaddingWrapper>

            <PaddingWrapper>
              <Button
                fullWidth
                secondary
                onClick={() =>
                  (window.location.href =
                    process.env.REACT_APP_API + "/auth/google")
                }
              >
                <ButtonAdornment start>
                  <IoLogoGoogle />
                </ButtonAdornment>
                Google
              </Button>
            </PaddingWrapper>

            <PaddingWrapper>
              <Button
                fullWidth
                secondary
                onClick={() =>
                  (window.location.href =
                    process.env.REACT_APP_API + "/auth/facebook")
                }
              >
                <ButtonAdornment start>
                  <SiFacebook />
                </ButtonAdornment>
                Facebook
              </Button>
            </PaddingWrapper>
          </Col>
        </Row>
      </AuthenticationLayout>
    );
  }
}

LoginPage.contextType = UserContext;
export default LoginPage;
