import React from "react";
import styled from "styled-components";
import NavbarLogo from "../../../assets/img/logo.svg";
import { ReactComponent as NotificationLogo } from "../../../assets/img/notification.svg";
import { Button, PaddingWrapper, ButtonAdornment } from "../../../ui";
import { IoMdAdd } from "react-icons/io";
import { Link } from "react-router-dom";
import { ImAttachment } from "react-icons/im";
import { CgFileDocument } from "react-icons/cg";
import { UserContext } from "../../../context/UserContext";

const SidebarContainer = styled.nav`
  padding: 20px;
`;

const Logo = styled.img`
  height: 40px;
  width: auto;
`;

const Sidebar = ({ activeRoute = "requests" }) => {
  const userContext = React.useContext(UserContext);
  let { requiredDocuments } = userContext.user;

  if (!requiredDocuments) requiredDocuments = [];

  let anyDocumentsToUpload = false;

  requiredDocuments.forEach((document) => {
    if (!document.files || !document.files.length) {
      anyDocumentsToUpload = true;
    }
  });

  return (
    <SidebarContainer>
      <div className="mt-2">
        <Link to="/">
          <Logo src={NavbarLogo} alt="Credit King logotipas" />
        </Link>
      </div>

      <PaddingWrapper className="mt-3">
        <Link to="/submit">
          <Button size="large" fullWidth>
            <ButtonAdornment end>
              <IoMdAdd />
            </ButtonAdornment>
            Nauja paraiška
          </Button>
        </Link>
      </PaddingWrapper>

      <div className="mt-3">
        <PaddingWrapper>
          <Link to="/">
            <Button
              size="large"
              active={activeRoute === "requests"}
              link
              left
              fullWidth
            >
              <CgFileDocument
                className="pr-2"
                size={20}
                fill="#4575F2"
                style={{
                  verticalAlign: "-3px",
                }}
              />
              Mano paraiškos
            </Button>
          </Link>
        </PaddingWrapper>

        <Link to="/documents">
          <Button
            size="large"
            active={activeRoute === "documents"}
            link
            left
            fullWidth
          >
            <ImAttachment
              className="pr-2"
              size={18}
              fill="#4575F2"
              style={{
                verticalAlign: "-3px",
              }}
            />
            Dokumentai
            {anyDocumentsToUpload && (
              <ButtonAdornment end>
                <NotificationLogo
                  style={{
                    verticalAlign: "9px",
                  }}
                />
              </ButtonAdornment>
            )}
          </Button>
        </Link>
      </div>
    </SidebarContainer>
  );
};

export default Sidebar;
