import React from "react";
import { Typography } from "../../ui";

const StepperHeader = ({ step, totalSteps = 4, title }) => (
  <div>
    <div>
      <Typography.Text grey className="mb-0">
        {step} / {totalSteps}
      </Typography.Text>
    </div>

    <Typography.Header1 reduceSizeMobile className="mt-0">
      {title}
    </Typography.Header1>
  </div>
);

export default StepperHeader;
