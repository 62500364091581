import React from "react";
import styled, { css } from "styled-components";
import { Typography } from "..";

const Wrapper = styled.div`
  position: relative;
  padding: 5px 0px;
  background: #f8f8f8;
  border-radius: 8px;
  border: none;
  padding: 15px 20px;
  border: 1px solid #f8f8f8;
  /* display: inline-block;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")}; */

  :focus-within {
    border: 1px solid #94b1ff;
  }

  ${({ error }) =>
    error &&
    css`
      border: 1px solid #d02b2b !important;
    `}

  ${({ withAdornment }) =>
    withAdornment &&
    css`
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
    `}
`;

const InputBox = styled.input`
  font-size: 16px;
  width: 100%;
  background: none !important;
  border: none !important;
  padding: 1px 0px;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;

  &:focus {
    border: none;
    outline: none;
  }

  ${({ label }) =>
    label &&
    css`
      position: relative;
      bottom: -5px;
    `}

  ${({ withAdornment }) =>
    withAdornment &&
    css`
      position: relative;
      flex: 1 1 auto;
      width: 1%;
      min-width: 0;
      margin-bottom: 0;
    `}
`;

const FloatingLabelStyle = css`
  top: 5px;
  left: 20px;
  font-size: 12px;
  opacity: 0.9;
`;

const Label = styled.label`
  font-size: 16px;
  position: absolute;
  pointer-events: none;
  left: 20px;
  top: 15px;
  transition: 0.3s ease all;
  color: #aaacb6;

  ${InputBox}:focus ~ & {
    ${FloatingLabelStyle}
  }

  /* display floating label if value exists */
  ${({ value, withAdornment }) =>
    (value !== "" || withAdornment) &&
    css`
      ${FloatingLabelStyle}
    `}
`;

const AdornmentWrapper = styled.div`
  display: flex;
  position: relative;
  bottom: -5px;
  padding: 1px 0px;
`;

const Adornment = styled.span`
  ${({ start }) =>
    start &&
    css`
      margin-right: 1px;
    `}

  ${({ end }) =>
    end &&
    css`
      margin-left: 1px;
    `}
`;

const Input = (props) => {
  const withAdornment = Boolean(props.startAdornment || props.endAdornment);
  const inputBox = React.useRef();

  return (
    <>
      <Wrapper
        error={props.error}
        fullWidth={props.fullWidth}
        withAdornment={withAdornment}
        onClick={() => {
          if (inputBox.current) {
            inputBox.current.focus();
          }
        }}
      >
        {props.startAdornment && (
          <AdornmentWrapper>
            <Adornment start>{props.startAdornment}</Adornment>
          </AdornmentWrapper>
        )}

        <InputBox {...props} ref={inputBox} withAdornment={withAdornment} />

        {props.endAdornment && (
          <AdornmentWrapper>
            <Adornment end>{props.endAdornment}</Adornment>
          </AdornmentWrapper>
        )}

        {props.label && (
          <Label value={props.value} withAdornment={withAdornment}>
            {props.label}
          </Label>
        )}
      </Wrapper>

      {props.helperText && (
        <Typography.Text
          size="small"
          weight={500}
          grey
          justify
          className="mb-0 mt-2"
        >
          {props.helperText}
        </Typography.Text>
      )}

      {props.errorText && (
        <Typography.Text size="small" weight={500} red className="mb-0 mt-2">
          {props.errorText}
        </Typography.Text>
      )}
    </>
  );
};

export default Input;
