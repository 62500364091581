const errors = {
  default: "Serverio klaida",
  required: "Šis laukas negali būti tuščias",
  requiredDocuments: "Pateikite privalomus dokumentus",
  invalidFormat: "Neteisingas formatas",
  "Password must be atleast 5 characters":
    "Slaptažodis privalo būti bent 5 simbolių ilgio",
  "User not found": "Vartotojas neegzistuoja",
  "User does not exist": "Neteisingas el. paštas",
  "Password does not match": "Neteisingas slaptažodis",
  "User already exists": "Vartotojas jau egzistuoja",
  "Invalid personal id": "Neteisingas asmens kodas",
};

export default errors;
