import React from "react";
import {
  ButtonAdornment,
  Input,
  PaddingWrapper,
  Select,
  Typography,
  Button,
} from "../../../../ui";
import { Formik, Field, Form } from "formik";
import * as yup from "yup";
import errors from "../../../../locale/lt_LT/errors";
import { FormRow } from "../../../../components";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { RequestApi } from "../../../../api";
import { toast } from "react-toastify";

const carYears = [];

for (let y = new Date().getFullYear(); y >= 1900; y--) {
  carYears.push(y);
}

const carValidationSchema = yup.object().shape({
  carInfo: yup.string(errors.invalidFormat).required(errors.required),
  carManufacturingYear: yup
    .string(errors.invalidFormat)
    .required(errors.required),
});

const CarExchangeSubview = ({ request, getRequest }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <>
      <Typography.Text size="lead">
        Įkeičiamo automobilio informacija
      </Typography.Text>
      <Formik
        initialValues={{
          carInfo: "",
          carManufacturingYear: "",
        }}
        onSubmit={(values) => {
          setIsLoading(true);

          RequestApi.patchExchangeInfo(request._id, values)
            .then(async () => {
              await getRequest();
              toast.dark("Paraiška sėkmingai atnaujinta");
            })
            .catch((error) => {
              toast.error(error);
            })
            .finally(() => {
              setIsLoading(false);
            });
        }}
        validationSchema={carValidationSchema}
      >
        {({ errors, touched }) => (
          <Form>
            <FormRow>
              <PaddingWrapper>
                <Field
                  label="Pagaminimo metai"
                  id="carManufacturingYear"
                  name="carManufacturingYear"
                  as={Select}
                  withBlankSelect
                  fullWidth
                  error={
                    errors.carManufacturingYear && touched.carManufacturingYear
                  }
                  errorText={
                    touched.carManufacturingYear && errors.carManufacturingYear
                  }
                  required
                >
                  {carYears.map((year) => (
                    <option value={year} key={year}>
                      {year}
                    </option>
                  ))}
                </Field>
              </PaddingWrapper>
            </FormRow>

            <FormRow size="extraLarge">
              <PaddingWrapper className="mt-0">
                <Field
                  label="Informacija apie automobilį"
                  id="carInfo"
                  name="carInfo"
                  type="text"
                  as={Input}
                  fullWidth
                  required
                  error={errors.carInfo && touched.carInfo}
                  errorText={touched.carInfo && errors.carInfo}
                  helperText="Nurodykite automobilio markę, modelį, kw, tūrį, kėbulo tipą, pavarų dėžę arba nuorodą į automobilio skelbimą."
                />
              </PaddingWrapper>
            </FormRow>

            <FormRow>
              <PaddingWrapper>
                <Button type="submit" disabled={isLoading} fullWidth>
                  <ButtonAdornment end>
                    <HiOutlineArrowNarrowRight />
                  </ButtonAdornment>
                  Tęsti
                </Button>
              </PaddingWrapper>
            </FormRow>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CarExchangeSubview;
