const request = {
  occupationType: {
    worker: "Dirbantis pagal darbo sutartį",
    official: "Valstybės tarnautojas",
    "self-employed": "Savarankiškai dirbantis asmuo",
    "working-abroad": "Dirbantis užsienyje",
    other: "Kita",
  },
  carCreditType: {
    buy: "Noriu įsigyti automobilį",
    exchange: "Turiu automobilį ir sutinku jį įkeisti",
  },
  workExperience: {
    "0-3": "0 iki 3 mėnesių",
    "4-12": "4 iki 12 mėnesių",
    "12-24": "12 iki 24 mėnesių",
    "24-36": "24 iki 36 mėnesių",
    "36-60": "36 iki 60 mėnesių",
    "60+": "Daugiau nei 60 mėnesių",
  },
  creditType: {
    spending: "Vartojimo paskola",
    car: "Automobilio paskola",
    housing: "Busto kreditas",
    refinancing: "Refinansavimas",
    "spending-real-estate-exchange": "Paskola su nekilnojamojo turto įkeitimu",
    "land-lot": "Paskola sklypui",
    "house-maintenance": "Paskola būsto remontui",
  },
  realEstateType: {
    flat: "Butas",
    house: "Namas",
    office: "Biuras",
    warehouse: "Sandėlis",
    "commercial-facilities": "Komercinės patalpos",
    "investment-object": "Investicinis objektas",
    "production-facilities": "Gamybinės patalpos",
    land: "Žemė",
  },
  status: {
    canceled: "Nepavyko susisiekti",
    unconfirmed: "Patvirtinkite tapatybę",
    no_offers: "Pasiūlymų nėra",
    waiting_info: "Laukia informacijos",
    waiting_partner_info: "Laukia informacijos",
    waiting_offer: "Laukia pasiūlymo",
    waiting_call: "Laukia skambučio",
    waiting_sign: "Laukia pasirašymo",
    signed: "Pasirašyta",
    inactive: "Neaktyvi",
    unavailable: "Nematoma",

    // artificial
    has_offer: "Gautas atsakymas",
  },
  documentType: {
    passport: "Pasas",
    "id-card": "Tapatybės kortelė",
  },
  educationLevel: {
    doctor: "Aukštasis (mokslų daktaras)",
    masters: "Aukštasis (magistras)",
    bachelor: "Aukštasis (bakalauras)",
    vocational: "Profesinis",
    secondary: "Vidurinis",
    "unfinished-secondary": "Nebaigtas vidurinis",
  },
};

export default request;
