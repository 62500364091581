import React from "react";
import { Col, Row } from "react-grid-system";
import { FileApi } from "../../api";
import { Button, PaddingWrapper, Separator, Typography } from "../../ui";
import UploadedFileLogo from "../../assets/img/uploaded-file.svg";
import DeleteLogo from "../../assets/img/delete.svg";
import { toast } from "react-toastify";

class FileUpload extends React.Component {
  constructor({
    setter,
    title,
    description,
    requirements,
    files,
    maxFiles,
    error,
    errorText,
  }) {
    super();

    if (!setter || !requirements || !maxFiles) {
      throw new Error("Missing parameters");
    }

    this.setter = setter;
    this.title = title;
    this.description = description;
    this.requirements = requirements;
    this.maxFiles = maxFiles;
    this.error = error;
    this.errorText = errorText;
    this.fileUploadButton = React.createRef();

    this.state = {
      files: files || [],
      isLoading: false,
    };
  }

  componentDidUpdate() {
    if (this.state.files !== this.props.files) {
      this.setState({
        files: this.props.files,
      });
    }
  }

  setFilePaths = () => {
    const filePaths = this.state.files.map((file) => {
      return {
        name: file.name,
        path: file.path,
      };
    });

    this.setter(filePaths);
  };

  handleFileUpload = async (event) => {
    if (!event.target || !event.target.files || event.target.files.length < 1) {
      return;
    }

    let files = [...event.target.files];

    if (
      files.length > this.maxFiles ||
      this.state.files.length + files.length > this.maxFiles
    ) {
      return toast.error("Viršytas failų kiekio limitas");
    }

    for (let file of files) {
      if (file.size > 8000000) {
        return toast.error("Viršytas failo dydžio limitas");
      }
    }

    const promises = [];
    this.setState({
      isLoading: true,
    });

    for (let file of files) {
      promises.push(FileApi.upload(file));
    }

    try {
      var resolved = await Promise.all(promises);
    } catch (error) {
      this.setState({ isLoading: false });

      return toast.error("Failų įkelti nepavyko");
    }

    files = files.map((file, index) => {
      if (!resolved[index].path) {
        toast.error("Failo įkelti nepavyko");
        return {};
      }

      file.path = resolved[index].path;
      return file;
    });

    // clear the errored ones
    this.fileUploadButton.current.value = "";

    files = files.filter((file) => file.path);
    this.setState(
      { isLoading: false, files: [...this.state.files, ...files] },
      () => this.setFilePaths()
    );
  };

  handleFileRemoval = (index) => {
    let { files } = this.state;

    files.splice(index, 1);
    this.setState({ files }, () => this.setFilePaths());
  };

  checkIfDisabled = () => {
    if (this.props.disabled) {
      return true;
    }

    if (this.state.isLoading) {
      return true;
    }

    if (this.state.files.length === this.maxFiles) {
      return true;
    }

    return false;
  };

  render() {
    const { error, errorText } = this.props;

    return (
      <>
        {this.title && (
          <Typography.Text size="lead">{this.title}</Typography.Text>
        )}

        {Boolean(error) && (
          <Typography.Text size="small" weight={500} red className="mb-0 mt-2">
            {errorText}
          </Typography.Text>
        )}

        {this.description && (
          <Typography.Text size="small" weight={500} justify grey>
            {this.description}
          </Typography.Text>
        )}

        <input
          accept="image/*, application/pdf"
          ref={this.fileUploadButton}
          onChange={this.handleFileUpload}
          multiple
          type="file"
          hidden
        />

        <Button
          type="button"
          secondary
          fullWidth
          onClick={() => this.fileUploadButton.current.click()}
          disabled={this.checkIfDisabled()}
        >
          Įkelti dokumentus
        </Button>

        {this.state.files && this.state.files.length > 0 && (
          <>
            <PaddingWrapper size="small">
              {this.state.files.map((file, index) => (
                <Row gutterWidth={0} className="my-3" key={index}>
                  <Col>
                    <span>
                      <img
                        src={UploadedFileLogo}
                        style={{
                          verticalAlign: "middle",
                        }}
                        alt="Dokumentas"
                      />
                    </span>
                    <Typography.InlineText
                      size="small"
                      purple
                      weight={500}
                      className="pl-2"
                    >
                      {file.name}
                    </Typography.InlineText>
                  </Col>
                  <Col xs={1} className="text-right">
                    <img
                      src={DeleteLogo}
                      style={{
                        verticalAlign: "middle",
                        cursor: "pointer",
                      }}
                      alt="Ištrinti dokumentą"
                      onClick={() => this.handleFileRemoval(index)}
                    />
                  </Col>
                </Row>
              ))}
            </PaddingWrapper>

            <Separator />
          </>
        )}

        <PaddingWrapper className="pb-0">
          <Typography.InlineText grey size="small">
            <ul className="pl-3 mb-0">
              {this.requirements.map((requirement, index) => (
                <li key={index} className="mb-2">
                  {requirement}
                </li>
              ))}
            </ul>
          </Typography.InlineText>
        </PaddingWrapper>
      </>
    );
  }
}

export default FileUpload;
