import React from "react";
import { Button, Typography } from "../../ui";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { Link } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { ReactComponent as AvatarLogo } from "../../assets/img/avatar.svg";
import { ReactComponent as ChevronDownLogo } from "../../assets/img/chevron-down.svg";
import { Visible } from "react-grid-system";

const UserMenu = ({
  activeRoute,
  disableProfile,
  hideSettings,
  showMyRequests,
}) => {
  const userContext = React.useContext(UserContext);
  const { user } = userContext;

  const handleLogout = () => {
    localStorage.removeItem("token");

    userContext.setContext({
      isAuthenticated: false,
      user: null,
    });
  };

  return (
    <Popup
      position="bottom right"
      closeOnDocumentClick
      arrow={false}
      trigger={
        <Button link>
          <AvatarLogo className="va-middle" />

          <Visible xl xxl>
            <Typography.InlineText weight={500} className="pl-2">
              {user.firstName} {user.lastName}{" "}
              <ChevronDownLogo
                className="pl-1"
                style={{
                  verticalAlign: "2px",
                }}
              />
            </Typography.InlineText>
          </Visible>
        </Button>
      }
    >
      <div className="menu fade-in">
        {!hideSettings && (
          <div>
            <Link to="/settings">
              <Button
                active={activeRoute === "settings"}
                fullWidth
                size="small"
                link
              >
                Paskyros nustatymai
              </Button>
            </Link>
          </div>
        )}

        {showMyRequests &&
          user &&
          user.creditRequests &&
          user.creditRequests.length > 0 && (
            <div>
              <Link to="/">
                <Button
                  active={activeRoute === "settings"}
                  fullWidth
                  size="small"
                  link
                >
                  Mano paraiškos
                </Button>
              </Link>
            </div>
          )}

        <div>
          <Button onClick={handleLogout} fullWidth size="small" link>
            Atsijungti
          </Button>
        </div>
      </div>
    </Popup>
  );
};

export default UserMenu;
