import React from "react";
import { Col, Row, Visible } from "react-grid-system";
import { RequestStatusBadge } from "../../../components";
import { Card, Typography, Href } from "../../../ui";
import { Link } from "react-router-dom";
import translations from "../../../locale/lt_LT/request";
import moment from "moment";
import "moment/locale/lt";

const Request = ({ request }) => (
  <Card className="my-3 py-4">
    {/* DESKTOP VERSION */}
    <Visible lg xl xxl>
      <Row className="text-center px-4" gutterWidth={5}>
        <Col sm={1} className="text-left">
          <Typography.InlineText>
            <Href
              to={
                request.status === "unconfirmed"
                  ? "/identity/confirmation/" + request._id
                  : "/requests/" + request._id
              }
              as={Link}
            >
              {request.id}
            </Href>
          </Typography.InlineText>
        </Col>
        <Col>
          <Typography.InlineText>
            {moment(request.createdAt).locale("lt").format("LL")}
          </Typography.InlineText>
        </Col>
        <Col>
          <Typography.InlineText>
            {
              translations.creditType[
                request.creditType === "spending" && request.subCreditType
                  ? request.subCreditType
                  : request.creditType
              ]
            }
          </Typography.InlineText>
        </Col>
        <Col>
          <Typography.InlineText>
            {request.creditAmount} €
          </Typography.InlineText>
        </Col>
        <Col>
          <Typography.InlineText>
            {request.creditLength} mėn.
          </Typography.InlineText>
        </Col>
        <Col className="text-right">
          <RequestStatusBadge request={request} />
        </Col>
      </Row>
    </Visible>

    {/* MOBILE/TABLET VERSION */}
    <Visible xs sm md>
      <div className="px-4">
        <Row>
          <Col>
            <Typography.InlineText greyDark size="micro">
              KODAS
            </Typography.InlineText>
          </Col>
          <Col className="text-right">
            <Typography.InlineText>
              <Href
                to={
                  request.status === "unconfirmed"
                    ? "/identity/confirmation/" + request._id
                    : "/requests/" + request._id
                }
                as={Link}
              >
                {request.id}
              </Href>
            </Typography.InlineText>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <Typography.InlineText greyDark size="micro">
              SUKŪRIMO DATA
            </Typography.InlineText>
          </Col>
          <Col className="text-right">
            <Typography.InlineText>
              {moment(request.createdAt).locale("lt").format("l")}
            </Typography.InlineText>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <Typography.InlineText greyDark size="micro">
              TIPAS
            </Typography.InlineText>
          </Col>
          <Col className="text-right" xs={8}>
            <Typography.InlineText>
              {
                translations.creditType[
                  request.creditType === "spending" && request.subCreditType
                    ? request.subCreditType
                    : request.creditType
                ]
              }
            </Typography.InlineText>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <Typography.InlineText greyDark size="micro">
              SUMA
            </Typography.InlineText>
          </Col>
          <Col className="text-right">
            <Typography.InlineText>
              {request.creditAmount} €
            </Typography.InlineText>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <Typography.InlineText greyDark size="micro">
              TERMINAS
            </Typography.InlineText>
          </Col>
          <Col className="text-right">
            <Typography.InlineText>
              {request.creditLength} mėn.
            </Typography.InlineText>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <Typography.InlineText greyDark size="micro">
              STATUSAS
            </Typography.InlineText>
          </Col>
          <Col className="text-right" xs={8}>
            <Typography.InlineText>
              <RequestStatusBadge request={request} />
            </Typography.InlineText>
          </Col>
        </Row>
      </div>
    </Visible>
  </Card>
);

export default Request;
