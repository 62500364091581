import React from "react";
import { Button, Card, PaddingWrapper, Select, Typography } from "../../../ui";
import * as yup from "yup";
import errors from "../../../locale/lt_LT/errors";
import { UserContext } from "../../../context/UserContext";
import { Field, Form, Formik } from "formik";
import { FileUpload } from "../../../components";
import { toast } from "react-toastify";
import { UserApi } from "../../../api";

const validationSchema = yup.object().shape({
  type: yup.string(errors.invalidFormat).required(errors.required),
  files: yup.array().of(yup.object()).required(errors.required),
});

const DocumentUploadForm = ({ documentsToUpload }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const userContext = React.useContext(UserContext);

  const handleDocumentUpload = async (values, { resetForm }) => {
    const { user } = userContext;
    const { type, files } = values;

    setIsLoading(true);

    try {
      await UserApi.postRequiredDocument(user._id, type, files);
    } catch (error) {
      setIsLoading(false);
      return toast.error("Nepavyko įkelti dokumentų");
    }

    setIsLoading(false);
    resetForm();

    toast.dark("Dokumentai sėkmingai įkelti");
    await userContext.getUser();
  };

  const isDisabled = !documentsToUpload.length;

  return (
    <Card>
      <div className="px-4 py-2">
        <Typography.Text size="lead" weight={500} className="mb-2">
          Įkelti naują dokumentą
        </Typography.Text>

        <Formik
          initialValues={{
            type: "",
            files: [],
          }}
          onSubmit={handleDocumentUpload}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, errors, touched }) => (
            <Form>
              <PaddingWrapper>
                <Field
                  label="Dokumento tipas"
                  name="type"
                  id="type"
                  fullWidth
                  error={errors.type && touched.type}
                  errorText={touched.type && errors.type}
                  disabled={isDisabled}
                  required
                  as={Select}
                >
                  <option value="" selected disabled></option>

                  {documentsToUpload.map((document, index) => (
                    <option value={document.type} key={index}>
                      {document.type}
                    </option>
                  ))}
                </Field>
              </PaddingWrapper>

              <PaddingWrapper>
                <FileUpload
                  disabled={isDisabled}
                  setter={(files) => setFieldValue("files", files)}
                  requirements={[
                    "Galite įkelti ne daugiau kaip 10 failų.",
                    "Maksimalus vieno failo dydis 8MB.",
                    "Galimi formatai: .jpg .jpeg .gif .png ir .pdf",
                  ]}
                  maxFiles={10}
                  files={values.files}
                />
              </PaddingWrapper>

              <PaddingWrapper>
                <Button
                  type="submit"
                  disabled={isLoading || isDisabled}
                  fullWidth
                >
                  Patvirtinti
                </Button>
              </PaddingWrapper>
            </Form>
          )}
        </Formik>
      </div>
    </Card>
  );
};

export default DocumentUploadForm;
