import styled from "styled-components";
import textSizeSwitcher from "../switchers/textSizeSwitcher";
import theme from "../../config/theme";
import { darken } from "polished";

const Href = styled.a`
  ${({ size }) => size && textSizeSwitcher(size)}

  color: ${theme.colors.blue};
  text-decoration: none;
  cursor: pointer;

  &:visited {
    color: ${theme.colors.blue};
  }

  &:hover {
    color: ${darken(0.1, theme.colors.blue)};
  }
`;

export default Href;
