import React from "react";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import { UserContext } from "./context/UserContext";

// IMPORT PAGES
import {
  LoginPage,
  RegisterPage,
  SubmitPage,
  IdentityConfirmationPage,
  RequestListPage,
  RequestPage,
  DocumentsPage,
  SettingsPage,
  ForgotPage,
  ResetPage,
} from "./pages";

const Router = () => {
  const userContext = React.useContext(UserContext);
  const { isAuthenticated } = userContext;

  const routes = {
    guest: [
      {
        path: "/",
        exact: true,
        render: () => <LoginPage />,
      },
      {
        path: "/register",
        exact: true,
        render: () => <RegisterPage />,
      },
      {
        path: "/forgot",
        exact: true,
        render: () => <ForgotPage />,
      },
      {
        path: "/reset/:token",
        exact: true,
        render: (props) => <ResetPage {...props} />,
      },
    ],
    auth: [
      {
        path: "/",
        exact: true,
        render: () => <RequestListPage />,
      },
      {
        path: "/requests/:id",
        exact: true,
        render: (props) => <RequestPage {...props} />,
      },
      {
        path: "/identity/confirmation/:id",
        exact: true,
        render: (props) => <IdentityConfirmationPage {...props} />,
      },
      {
        path: "/submit",
        exact: true,
        render: () => <SubmitPage />,
      },
      {
        path: "/documents",
        exact: true,
        render: () => <DocumentsPage />,
      },
      {
        path: "/settings",
        exact: true,
        render: () => <SettingsPage />,
      },
    ],
  };

  return (
    <BrowserRouter>
      <Switch>
        {!isAuthenticated &&
          routes.guest.map((route, index) => <Route {...route} key={index} />)}

        {isAuthenticated &&
          routes.auth.map((route, index) => <Route {...route} key={index} />)}

        {/* authentication route by URL */}
        <Route
          path="/authenticate/:token"
          exact
          render={({ match }) => {
            const { token } = match.params;

            localStorage.setItem("token", token);
            window.location.href = "/";
          }}
        />

        {/* invalid path fallback, redirect to home */}
        <Route path="*" render={() => <Redirect to="/" />} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
