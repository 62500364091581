import styled from "styled-components";

const SidebarWrapper = styled.aside`
  background: white;
  left: 0;
  height: 100vh;
  width: 350px;
  max-width: 350px;
  position: fixed;
  z-index: 5;
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
  overflow-y: auto;

  @media (max-width: 1199px) {
    display: none;
  }
`;

export default SidebarWrapper;
