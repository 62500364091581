import React from "react";
import { ReactComponent as FreeLogo } from "../../../../assets/img/free.svg";
import { Typography, PaddingWrapper } from "../../../../ui";

const Free = () => (
  <>
    <div>
      <FreeLogo />
    </div>

    <Typography.Header1 white>Paslauga visiškai nemokama</Typography.Header1>

    <PaddingWrapper size="small">
      <Typography.Text className="mt-0 mb-0" white>
        Taip, paskolų palyginimas Credit King platformoje nieko nekainuoja!
      </Typography.Text>
    </PaddingWrapper>
  </>
);

export default Free;
