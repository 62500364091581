import styled from "styled-components";

const NavbarLogo = styled.img`
  height: 40px;
  width: auto;

  @media (max-width: 576px) {
    height: 30px;
  }
`;

export default NavbarLogo;
