import React from "react";
import Timer from "react-compound-timer/build";
import { IdentityApi } from "../../../api";
import { Dialog, Loader, PaddingWrapper, Typography } from "../../../ui";

class SmartidIdentityDialog extends React.Component {
  state = {
    isLoading: true,
    errored: false,
    complete: false,
  };

  handleConfirmation = async () => {
    const { requestConfirmationId } = this.props;
    this.setState({ isLoading: true });

    try {
      var { code, sessionId } = await IdentityApi.createSmartidSession(
        requestConfirmationId
      );
    } catch (error) {
      return this.setState({
        errored: error,
        isLoading: false,
      });
    }

    this.setState({
      errored: false,
      code: code,
    });

    try {
      await IdentityApi.createSmartIdAuthentication(
        requestConfirmationId,
        sessionId
      );
    } catch (error) {
      return this.setState({
        errored: error,
        isLoading: false,
      });
    }

    this.setState({
      errored: false,
      complete: true,
    });

    return setTimeout(() => window.location.reload(), 1500);
  };

  render() {
    const { isOpen, onClose } = this.props;

    return (
      <Dialog
        isOpen={isOpen}
        onClose={onClose}
        onEnter={this.handleConfirmation}
        title="Smart-ID tapatybės patvirtinimas"
      >
        {this.state.isLoading && (
          <>
            <Typography.Text greyDark className="mt-0">
              Tapatybė tvirtinama. Suveskite savo PIN kodą Smart-ID
              programėlėje.
            </Typography.Text>

            {this.state.code ? (
              <Typography.Header1 center className=" my-3">
                {this.state.code}
              </Typography.Header1>
            ) : (
              <div className="text-center">
                <Loader className="my-3" />
              </div>
            )}

            <Timer
              initialTime={1000 * 120}
              direction="backward"
              formatValue={(n) => {
                return Number(n) > 9 ? "" + n : "0" + n;
              }}
            >
              {() => (
                <Typography.Header1 center>
                  <Timer.Minutes /> : <Timer.Seconds />
                </Typography.Header1>
              )}
            </Timer>
          </>
        )}

        {this.state.errored && (
          <PaddingWrapper>
            {this.state.errored === "Invalid account" ? (
              <Typography.Text justify greyDark>
                Tapatybės patvirtinti nepavyko, kadangi naudojate Smart-ID Basic
                paskyrą. Atnaujinti savo Smart-ID Basic paskyrą į visiškos
                prieigos Smart-ID paskyrą galite Smart-ID programėlėje savo
                telefone.
              </Typography.Text>
            ) : (
              <Typography.Text justify greyDark>
                Tapatybės patvirtinti nepavyko, pabandykite dar kartą.
              </Typography.Text>
            )}
          </PaddingWrapper>
        )}

        {this.state.complete && (
          <Typography.Text justify greyDark>
            Tapatybė sekmingai patvirtinta. Neišjunkite puslapio.
          </Typography.Text>
        )}
      </Dialog>
    );
  }
}

export default SmartidIdentityDialog;
