import React from "react";
import { Col, Row, Visible } from "react-grid-system";
import { Card, Typography } from "../../../ui";
import translations from "../../../locale/lt_LT/request";
import moment from "moment";
import "moment/locale/lt";
import { RequestStatusBadge } from "../../../components";

const RequestHeader = ({ request }) => (
  <Card className="py-3 px-3 px-sm-4">
    {/* DESKTOP VERSION */}
    <Visible lg xl xxl>
      <Row className="text-left px-3">
        <Col>
          <Typography.InlineText grey size="micro">
            SUKŪRIMO DATA
          </Typography.InlineText>
        </Col>
        <Col>
          <Typography.InlineText grey size="micro">
            TIPAS
          </Typography.InlineText>
        </Col>
        <Col>
          <Typography.InlineText grey size="micro">
            SUMA
          </Typography.InlineText>
        </Col>
        <Col>
          <Typography.InlineText grey size="micro">
            TERMINAS
          </Typography.InlineText>
        </Col>
        <Col md={3} lg={2.5} className="text-center">
          <Typography.InlineText grey size="micro">
            STATUSAS
          </Typography.InlineText>
        </Col>
      </Row>

      <Row className="text-left mt-1 px-3">
        <Col>
          <Typography.InlineText>
            {moment(request.createdAt).locale("lt").format("LL")}
          </Typography.InlineText>
        </Col>
        <Col>
          <Typography.InlineText>
            {
              translations.creditType[
                request.creditType === "spending" && request.subCreditType
                  ? request.subCreditType
                  : request.creditType
              ]
            }
          </Typography.InlineText>
        </Col>
        <Col>
          <Typography.InlineText>
            {request.creditAmount} €
          </Typography.InlineText>
        </Col>
        <Col>
          <Typography.InlineText>
            {request.creditLength} mėn.
          </Typography.InlineText>
        </Col>
        <Col md={3} lg={2.5} className="text-center">
          <RequestStatusBadge request={request}></RequestStatusBadge>
        </Col>
      </Row>
    </Visible>

    {/* MOBILE VERSION */}
    <Visible xs sm md>
      <div className="py-3">
        <Row>
          <Col>
            <Typography.InlineText greyDark size="micro">
              SUKŪRIMO DATA
            </Typography.InlineText>
          </Col>
          <Col className="text-right">
            <Typography.InlineText>
              {moment(request.createdAt).locale("lt").format("l")}
            </Typography.InlineText>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col xs={3}>
            <Typography.InlineText greyDark size="micro">
              TIPAS
            </Typography.InlineText>
          </Col>
          <Col className="text-right" xs={9}>
            <Typography.InlineText>
              {
                translations.creditType[
                  request.creditType === "spending" && request.subCreditType
                    ? request.subCreditType
                    : request.creditType
                ]
              }
            </Typography.InlineText>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <Typography.InlineText greyDark size="micro">
              SUMA
            </Typography.InlineText>
          </Col>
          <Col className="text-right">
            <Typography.InlineText>
              {request.creditAmount} €
            </Typography.InlineText>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <Typography.InlineText greyDark size="micro">
              TERMINAS
            </Typography.InlineText>
          </Col>
          <Col className="text-right">
            <Typography.InlineText>
              {request.creditLength} mėn.
            </Typography.InlineText>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col xs={3}>
            <Typography.InlineText greyDark size="micro">
              STATUSAS
            </Typography.InlineText>
          </Col>
          <Col className="text-right" xs={9}>
            <Typography.InlineText>
              <RequestStatusBadge request={request} />
            </Typography.InlineText>
          </Col>
        </Row>
      </div>
    </Visible>
  </Card>
);

export default RequestHeader;
