import React from "react";
import { Row, Col, Visible } from "react-grid-system";
import { Link } from "react-router-dom";
import { Typography, Href, Button } from "../../ui";
import NavbarLogo from "./NavbarLogo";
import NavbarWrapper from "./NavbarWrapper";
import NavbarNavigation from "./NavbarNavigation";
import Logo from "../../assets/img/logo.svg";
import { UserContext } from "../../context/UserContext";
import UserMenu from "../UserMenu/UserMenu";

const Navbar = ({ page, hideSettings, showMyRequests }) => {
  const userContext = React.useContext(UserContext);
  const { isAuthenticated, user } = userContext;

  return (
    <NavbarWrapper>
      <Row gutterWidth={0}>
        <Col className="my-auto">
          <Href to="/" as={Link}>
            <NavbarLogo src={Logo} />
          </Href>
        </Col>
        <Col className="my-auto">
          <NavbarNavigation>
            {!isAuthenticated && (page === "login" || !page) && (
              <>
                <Visible lg xl xxl>
                  <Typography.InlineText greyDark className="pr-2">
                    Dar neturite paskyros?
                  </Typography.InlineText>{" "}
                </Visible>

                <Button link active size="small" to="/register" as={Link}>
                  Registruotis
                </Button>
              </>
            )}

            {!isAuthenticated && page === "register" && (
              <>
                <Visible lg xl xxl>
                  <Typography.InlineText greyDark className="pr-2">
                    Jau turite paskyrą?
                  </Typography.InlineText>{" "}
                </Visible>

                <Button link active size="small" to="/" as={Link}>
                  Prisijunkite
                </Button>
              </>
            )}

            {isAuthenticated && user && (
              <UserMenu
                hideSettings={hideSettings}
                showMyRequests={showMyRequests}
              />
            )}
          </NavbarNavigation>
        </Col>
      </Row>
    </NavbarWrapper>
  );
};

export default Navbar;
