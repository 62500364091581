import React from "react";
import { Col, Row } from "react-grid-system";
import { AuthenticationLayout } from "../../layouts";
import { Button, Dialog, PaddingWrapper, Typography } from "../../ui";
import CreditRequestStep from "./steps/CreditRequestStep";
import PersonalInfoStep1 from "./steps/PersonalInfoStep1";
import PersonalInfoStep2 from "./steps/PersonalInfoStep2";
import BackLogo from "../../assets/img/back.svg";
import { UserContext } from "../../context/UserContext";
import { RequestApi, UserApi } from "../../api";
import queryString from "query-string";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

class SubmitPage extends React.Component {
  constructor(props, context) {
    super();

    let calculator = {};

    try {
      calculator = JSON.parse(localStorage.getItem("calculator"));
    } catch {
      calculator = {};
    }

    if (calculator === null) {
      calculator = {};
    }

    const creditAmountRanges = [
      "1000",
      "3000",
      "5000",
      "10000",
      "15000",
      "other",
    ];
    const creditLengthRanges = ["6", "12", "24", "36", "48", "80", "other"];

    this.state = {
      latestSubmissionStateExists: false,
      step: 0,
      creditRequestStepValues: {
        creditType: calculator.creditType || "spending",
        creditAmount: calculator.creditAmount,
        creditLength: calculator.creditLength,
        creditAmountRange: calculator.creditAmount
          ? creditAmountRanges.includes(calculator.creditAmount)
            ? calculator.creditAmount
            : "other"
          : String(5000),

        creditLengthRange: calculator.creditLength
          ? creditLengthRanges.includes(calculator.creditLength)
            ? calculator.creditLength
            : "other"
          : String(36),
        carInfo: "",
        carCreditType: "",
        carManufacturingYear: "",
        refinancingFilePaths: [],
      },
      personalInfoStep1Values: {
        firstName: context.user.firstName || "",
        lastName: context.user.lastName || "",
        personalID: "",
        educationLevel: "",
        occupationType: "",
        workExperience: "",
        personalIncome: "",
        financialObligations: "",
        housingObligations: "",
        futureFinancialSituationChange: false,
        futurePersonalIncome: "",
        futureFinancialObligations: "",
        ownsRealEstate: false,
        ownsCar: false,
        beneficialOwner: true,
        isRelatedToPolitics: false,
        isRelatedToPoliticsHimself: false,
        politicallyActivePersonFirstName: "",
        politicallyActivePersonLastName: "",
        politicallyActivePersonDuties: "",
        documentFilePaths: [],
      },
      personalInfoStep2Values: {
        residenceMunicipality: "",
        residenceCity: "",
        residenceAddress: "",

        residenceStreetAddress: "",
        residenceHouseNumber: "",
        residenceFlatNumber: "",
        residencePostalCode: "",

        childrenCount: 0,
        maritalStatus: "not-married",
        withCoApplicant: false,
        partnerFirstName: "",
        partnerLastName: "",
        partnerContactNumber: "",
        partnerEmail: "",
        partnerPersonalID: "",
        partnerPersonalIncome: "",
        partnerFinancialObligations: "",
        rulesAccepted: false,
        personalInfoAccepted: false,
        privacyAccepted: false,
      },
    };
  }

  getGoogleAnalyticsClientId = () => {
    try {
      window.ga((tracker) => {
        let clientId = tracker.get("clientId");

        this.setState({
          analyticsClientId: clientId,
        });
      });
    } catch {
      console.log("Unable to retreive google analytics client id");
    }
  };

  async componentDidMount() {
    setTimeout(() => {
      this.getGoogleAnalyticsClientId();
    }, 2000);

    await this.context.getUser();

    const { user } = this.context;

    if (!user) return;

    const { latestSubmissionState } = user;

    if (
      !latestSubmissionState ||
      Object.keys(latestSubmissionState).length === 0
    ) {
      return;
    }

    this.setState({
      latestSubmissionStateExists: true,
    });
  }

  changeValues = (target, values) => {
    this.setState({
      [target]: values,
    });
  };

  incrementStep = () => {
    if (localStorage.getItem("calculator")) {
      localStorage.removeItem("calculator");
    }

    window.scrollTo({ top: 0, behavior: "auto" });

    this.setState(
      {
        step: this.state.step + 1,
      },
      this.submitLatestSubmissionState
    );
  };

  decrementStep = () => {
    if (this.state.step === 0) return;

    window.scrollTo({ top: 0, behavior: "auto" });

    this.setState({
      step: this.state.step - 1,
    });
  };

  submitLatestSubmissionState = async () => {
    const state = { ...this.state, latestSubmissionStateExists: false };
    const userId = this.context.user._id;

    return UserApi.postLatestSubmissionState(userId, state);
  };

  handleSubmit = async () => {
    const {
      creditRequestStepValues,
      personalInfoStep1Values,
      personalInfoStep2Values,
    } = this.state;

    const newRequestObject = {
      ...creditRequestStepValues,
      ...personalInfoStep1Values,
      ...personalInfoStep2Values,
    };

    newRequestObject.ref = Cookies.get("creditking_ref");
    newRequestObject.facebookPixelId = Cookies.get("_fbp") || null;
    newRequestObject.analyticsClientId = this.state.analyticsClientId;

    if (
      ["land-lot", "house-maintenance"].includes(newRequestObject.creditType)
    ) {
      newRequestObject.subCreditType = newRequestObject.creditType;
      newRequestObject.creditType = "spending";
    }

    try {
      var request = await RequestApi.submitRequest(newRequestObject);
    } catch (error) {
      return toast.error(error);
    }

    window.location.href = "/identity/confirmation/" + request._id;
  };

  render() {
    const { query } = queryString.parseUrl(window.location.href);
    const { latestSubmissionStateExists } = this.state;

    const { user } = this.context;

    window.history.replaceState(
      null,
      null,
      `?step=${this.state.step + 1}` +
        (query.registered ? "&registered=true" : "") +
        (user.marketingAccept && this.state.step === 0 ? "&newsletter=on" : "")
    );

    return (
      <>
        {/* <TopProgressBar /> */}

        {latestSubmissionStateExists && (
          <Dialog
            isOpen={latestSubmissionStateExists}
            title={"Sveiki sugrįžę"}
            onClose={() =>
              this.setState({ latestSubmissionStateExists: false })
            }
          >
            <Typography.Text greyDark className="mt-0">
              Jūs nebaigėte pildyti paskutinės paskolos paraiškos. Galite tęsti
              jos pildymą nuo ten kur pabaigėte arba pildyti naują paraišką.
            </Typography.Text>

            <PaddingWrapper>
              <Button
                fullWidth
                onClick={() => {
                  this.setState(this.context.user.latestSubmissionState, () => {
                    this.setState({ latestSubmissionStateExists: false });
                  });
                }}
              >
                Tęsti paraiškos pildymą
              </Button>

              <Button
                link
                fullWidth
                onClick={() =>
                  this.setState({ latestSubmissionStateExists: false })
                }
                className="mt-2"
              >
                Pildyti naują paraišką
              </Button>
            </PaddingWrapper>
          </Dialog>
        )}

        <AuthenticationLayout
          page="submit"
          // hideMenu={true}
          hideSettings={true}
          showMyRequests={true}
          sideView={this.state.step === 0 ? "secure" : "fast"}
        >
          <Row>
            <Col md={12} lg={2}>
              {this.state.step > 0 && (
                <Button secondary fullWidth onClick={this.decrementStep}>
                  <img
                    src={BackLogo}
                    style={{
                      verticalAlign: "middle",
                    }}
                    alt="Atgal"
                  />
                </Button>
              )}
            </Col>
            <Col lg={9} xl={9} xxl={8}>
              {this.state.step === 0 && (
                <CreditRequestStep
                  values={this.state.creditRequestStepValues}
                  setValues={(values) => {
                    this.changeValues("creditRequestStepValues", values);
                    this.incrementStep();
                  }}
                  registered={query.registered}
                />
              )}

              {this.state.step === 1 && (
                <PersonalInfoStep1
                  values={this.state.personalInfoStep1Values}
                  setValues={(values) => {
                    this.changeValues("personalInfoStep1Values", values);
                    this.incrementStep();
                  }}
                />
              )}

              {this.state.step === 2 && (
                <PersonalInfoStep2
                  values={this.state.personalInfoStep2Values}
                  setValues={async (values) => {
                    await new Promise((resolve) => {
                      this.setState(
                        {
                          personalInfoStep2Values: values,
                        },
                        () => resolve()
                      );
                    });

                    return this.handleSubmit();
                  }}
                />
              )}
            </Col>
          </Row>
        </AuthenticationLayout>
      </>
    );
  }
}

SubmitPage.contextType = UserContext;
export default SubmitPage;
