import React from "react";
import { Col, Row, ScreenClassRender } from "react-grid-system";
import { Card, Typography } from "../../../ui";
import OfferHeader from "../components/OfferHeader";
import translations from "../../../locale/lt_LT/request";
import { ReactComponent as HandLogo } from "../../../assets/img/hand.svg";

const SignedRequestView = ({ request }) => {
  const { acceptedOffer } = request;

  return (
    <>
      <OfferHeader creditConfirmation={acceptedOffer} request={request} />

      <div className="mt-4">
        <ScreenClassRender
          render={(screenClass) => (
            <Row
              {...(["xs", "sm", "md"].includes(screenClass)
                ? {
                    gutterWidth: 0,
                  }
                : {})}
            >
              <Col xs={12} sm={12} md={12} lg={8}>
                <Card className="h-100">
                  <div className="p-4">
                    <Row>
                      <Col xs={12}>
                        <div style={{ display: "inline-block" }} class="mr-3">
                          <HandLogo
                            style={{
                              marginBottom: "-5px",
                            }}
                          />
                        </div>

                        <div style={{ display: "inline-block" }}>
                          <Typography.InlineText greyDark size="micro">
                            TIPAS
                          </Typography.InlineText>

                          <div>
                            <Typography.Text
                              size="lead"
                              weight={500}
                              className="m-0"
                            >
                              {
                                translations.creditType[
                                  request.creditType === "spending" &&
                                  request.subCreditType
                                    ? request.subCreditType
                                    : request.creditType
                                ]
                              }
                            </Typography.Text>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row className="mt-4">
                      <Col xs={12} sm={12} md={4}>
                        <Typography.InlineText greyDark size="micro">
                          PASKOLOS SUMA
                        </Typography.InlineText>

                        <div>
                          <Typography.Header1 className="m-0">
                            {request.signedAmount} €
                          </Typography.Header1>
                        </div>
                      </Col>

                      <Col xs={6} sm={6} md={4}>
                        <Typography.InlineText greyDark size="micro">
                          MĖN. ĮMOKA
                        </Typography.InlineText>

                        <div>
                          <Typography.Header1 className="m-0">
                            {request.signedMonthlyPayment} €
                          </Typography.Header1>
                        </div>
                      </Col>

                      <Col xs={6} sm={6} md={4}>
                        <Typography.InlineText greyDark size="micro">
                          TERMINAS
                        </Typography.InlineText>

                        <div>
                          <Typography.Header1 inline className="m-0">
                            {request.signedLength}
                          </Typography.Header1>{" "}
                          <Typography.InlineText weight={500} size="micro">
                            mėn.
                          </Typography.InlineText>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </Col>

              <Col
                xs={12}
                sm={12}
                md={12}
                lg={4}
                {...(["xs", "sm", "md"].includes(screenClass)
                  ? {
                      className: "mt-4",
                    }
                  : {})}
              >
                <Card className="h-100">
                  <div className="p-4">
                    <Row>
                      <Col>
                        <Typography.InlineText greyDark size="micro">
                          PALŪKANOS
                        </Typography.InlineText>
                      </Col>
                      <Col className="text-right">
                        <Typography.InlineText weight={500} size="lead">
                          {request.signedInterestRate} %
                        </Typography.InlineText>
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col>
                        <Typography.InlineText greyDark size="micro">
                          BVKKMN
                        </Typography.InlineText>
                      </Col>
                      <Col className="text-right">
                        <Typography.InlineText weight={500} size="lead">
                          {request.signedBVKKMN} %
                        </Typography.InlineText>
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col xs={8}>
                        <Typography.InlineText greyDark size="micro">
                          SUTARTIES MOKESTIS
                        </Typography.InlineText>
                      </Col>
                      <Col className="text-right">
                        <Typography.InlineText weight={500} size="lead">
                          {request.signedFee || "0"} €
                        </Typography.InlineText>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </Col>
            </Row>
          )}
        />
      </div>
    </>
  );
};

export default SignedRequestView;
