const getRequestStatus = (request) => {
  let { status } = request;

  if (status === "waiting_offer") {
    if (request.creditConfirmations && request.creditConfirmations.length > 0) {
      status = "has_offer";
    }
  }

  return status;
};

export default getRequestStatus;
