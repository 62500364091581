import React from "react";
import { Typography } from "../../../ui";
import RequestHeader from "../components/RequestHeader";
import { ReactComponent as NoOffersLogo } from "../../../assets/img/no-offers.svg";

const NoOffersRequestView = ({ request }) => {
  return (
    <>
      <RequestHeader request={request} />

      <div className="text-center mt-5">
        <div>
          <NoOffersLogo />
        </div>

        <Typography.Header1 reduceSizeMobile className="mt-2">
          Kredito pasiūlymų nėra
        </Typography.Header1>

        <Typography.Text grey>
          Apgailestaujame, tačiau šiuo metu kreditoriai negali suteikti Jums
          <br />
          paskolos Jūsų norimomis sąlygomis. Paraišką galite bandyti pateikti
          <br />
          dar kartą, tai visiškai nieko nekainuoja ir neturi įtakos Jūsų kredito
          istorijai.
        </Typography.Text>
      </div>
    </>
  );
};

export default NoOffersRequestView;
