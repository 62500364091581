import React from "react";
import { Row, Col } from "react-grid-system";
import { AuthenticationLayout } from "../../layouts";
import {
  PaddingWrapper,
  Typography,
  Input,
  Button,
  ButtonAdornment,
  Href,
  Alert,
} from "../../ui";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { IoLogoGoogle } from "react-icons/io";
import { SiFacebook } from "react-icons/si";
import { Formik, Field, Form } from "formik";
import * as yup from "yup";
import errors from "../../locale/lt_LT/errors";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { UserApi } from "../../api";
import queryString from "query-string";

const validationSchema = yup.object().shape({
  firstName: yup
    .string(errors.invalidFormat)
    .test("only-letters", errors.invalidFormat, (val) => {
      return !/\d/.test(val);
    })
    .required(errors.required),
  lastName: yup
    .string(errors.invalidFormat)
    .test("only-letters", errors.invalidFormat, (val) => {
      return !/\d/.test(val);
    })
    .required(errors.required),
  email: yup
    .string(errors.invalidFormat)
    .email(errors.invalidFormat)
    .required(errors.required),
  contactNumber: yup
    .string(errors.invalidFormat)
    .matches(/^[0-9]+$/, errors.invalidFormat)
    .min(8, errors.invalidFormat)
    .max(8, errors.invalidFormat)
    .required(errors.required),
  password: yup.string(errors.invalidFormat).required(errors.required),
});

const RegisterPage = () => {
  const history = useHistory();
  const userContext = React.useContext(UserContext);

  // React.useEffect(() => {
  //   let query = queryString.parse(window.location.search);

  //   if (query && (query.contactNumber || query.email)) {
  //     RequestApi.postPotentialRequest(query);
  //   }
  // }, []);

  const [error, setError] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const parsedQueryString = queryString.parse(window.location.search);

  // Landing page redirect, save data just in case
  const {
    creditType,
    creditAmount,
    creditLength,
    contactNumber,
    email,
  } = parsedQueryString;

  if (creditType && creditAmount && creditLength) {
    localStorage.setItem(
      "calculator",
      JSON.stringify({ creditType, creditAmount, creditLength })
    );
  }

  // social media
  const firstName = parsedQueryString.firstName || "";
  const lastName = parsedQueryString.lastName || "";

  const facebookId = parsedQueryString.facebookId || null;
  const googleId = parsedQueryString.googleId || null;

  return (
    <AuthenticationLayout page="register" sideView="free">
      <Row gutterWidth={0}>
        <Col xs={12} sm={12} md={10} lg={8} xl={7} xxl={6} className="mx-auto">
          {error && <Alert danger>{error}</Alert>}

          <Typography.Header1 reduceSizeMobile>
            Susikurkite paskyrą
          </Typography.Header1>

          <Typography.Text grey justify weight={500} className="mt-0 mb-4">
            Norėdami gauti mūsų partnerių pasiūlymus - užsiregistruokite. Tai
            truks vos kelias minutes.
          </Typography.Text>

          <Formik
            initialValues={{
              firstName,
              lastName,
              email: email || "",
              contactNumber: contactNumber || "",
              password: "",
              marketingAccept: true,

              // social logins
              facebookId,
              googleId,
            }}
            onSubmit={async (values) => {
              setIsLoading(true);
              setError(null);

              let userAlreadyExists = false;

              // register user
              try {
                await UserApi.register(values);
              } catch (error) {
                if (error !== errors["User already exists"]) {
                  setIsLoading(false);
                  setError(error);

                  return;
                }

                userAlreadyExists = true;
              }

              // login
              try {
                const token = await UserApi.login(
                  values.email,
                  values.password
                );

                localStorage.setItem("token", token);

                var user = await UserApi.getUser();
              } catch (_error) {
                let error = _error;

                if (
                  error === errors["Password does not match"] &&
                  userAlreadyExists
                ) {
                  error =
                    errors["User already exists"] +
                    ". " +
                    errors["Password does not match"];
                }

                setIsLoading(false);
                setError(error);

                return;
              }

              if (!user) {
                setIsLoading(false);
                setError(errors.default);

                return;
              }

              await userContext.setContext({
                isAuthenticated: true,
                user,
              });

              history.push("/submit?registered=true");
            }}
            validationSchema={validationSchema}
          >
            {({ values, setFieldValue, errors, touched }) => (
              <Form>
                <PaddingWrapper className="mt-0">
                  <Field
                    label="Vardas"
                    id="firstName"
                    name="firstName"
                    type="text"
                    as={Input}
                    fullWidth
                    error={errors.firstName && touched.firstName}
                    errorText={touched.firstName && errors.firstName}
                    required
                    autoComplete="given-name"
                  />
                </PaddingWrapper>

                <PaddingWrapper>
                  <Field
                    label="Pavardė"
                    id="lastName"
                    name="lastName"
                    type="text"
                    as={Input}
                    fullWidth
                    error={errors.lastName && touched.lastName}
                    errorText={touched.lastName && errors.lastName}
                    required
                    autoComplete="family-name"
                  />
                </PaddingWrapper>

                <PaddingWrapper>
                  <Field
                    label="Elektroninis paštas"
                    id="email"
                    name="email"
                    type="email"
                    as={Input}
                    fullWidth
                    error={errors.email && touched.email}
                    errorText={touched.email && errors.email}
                    required
                    autoComplete="email"
                  />
                </PaddingWrapper>

                <PaddingWrapper>
                  <Field
                    label="Telefono numeris"
                    id="contactNumber"
                    name="contactNumber"
                    type="text"
                    as={Input}
                    fullWidth
                    error={errors.contactNumber && touched.contactNumber}
                    errorText={touched.contactNumber && errors.contactNumber}
                    startAdornment="+370"
                    required
                    autoComplete="off"
                  />
                </PaddingWrapper>

                <PaddingWrapper>
                  <Field
                    label="Slaptažodis"
                    id="password"
                    name="password"
                    type="password"
                    as={Input}
                    fullWidth
                    error={errors.password && touched.password}
                    errorText={touched.password && errors.password}
                    required
                    autoComplete="new-password"
                  />
                </PaddingWrapper>

                <PaddingWrapper>
                  <Button type="submit" disabled={isLoading} fullWidth>
                    <ButtonAdornment end>
                      <HiOutlineArrowNarrowRight />
                    </ButtonAdornment>
                    Pradėti registraciją
                  </Button>
                </PaddingWrapper>

                <PaddingWrapper className="text-center">
                  <Typography.InlineText center grey>
                    Taip pat galite pradėti registraciją <br /> per socialinius
                    tinklus
                  </Typography.InlineText>
                </PaddingWrapper>

                <PaddingWrapper>
                  <Button
                    type="button"
                    fullWidth
                    secondary
                    onClick={() =>
                      (window.location.href =
                        process.env.REACT_APP_API + "/auth/google")
                    }
                  >
                    <ButtonAdornment start>
                      <IoLogoGoogle />
                    </ButtonAdornment>
                    Google
                  </Button>
                </PaddingWrapper>

                <PaddingWrapper>
                  <Button
                    type="button"
                    fullWidth
                    secondary
                    onClick={() =>
                      (window.location.href =
                        process.env.REACT_APP_API + "/auth/facebook")
                    }
                  >
                    <ButtonAdornment start>
                      <SiFacebook />
                    </ButtonAdornment>
                    Facebook
                  </Button>
                </PaddingWrapper>

                {values.marketingAccept && (
                  <PaddingWrapper>
                    <Typography.Text grey size="small" className="text-center">
                      Jeigu nesutinkate gauti naujienų ir kitos naudingos
                      informacijos į savo el. paštą{" "}
                      <Href
                        href={null}
                        onClick={() => setFieldValue("marketingAccept", false)}
                      >
                        spauskite čia
                      </Href>
                      .
                    </Typography.Text>
                  </PaddingWrapper>
                )}
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </AuthenticationLayout>
  );
};

export default RegisterPage;
