import styled, { css } from "styled-components";

const ButtonAdornment = styled.i`
  font-size: 24px;
  position: absolute;

  ${({ start }) =>
    start &&
    css`
      left: 15px;
    `}

  ${({ end }) =>
    end &&
    css`
      right: 15px;
    `}


  top: 56%;
  transform: translateY(-50%);
`;

export default ButtonAdornment;
