import React from "react";
import { ReactComponent as SecureLogo } from "../../../../assets/img/secure.svg";
import { Typography, PaddingWrapper } from "../../../../ui";

const Secure = () => (
  <>
    <div>
      <SecureLogo />
    </div>

    <Typography.Header1 white>
      Garantuojame Jūsų duomenų saugumą
    </Typography.Header1>

    <PaddingWrapper size="small">
      <Typography.Text className="mt-0 mb-0" white>
        Credit King klientų duomenys ir su klientais susijusi informacija
        siunčiama šifruotais kanalais.
      </Typography.Text>
    </PaddingWrapper>

    <PaddingWrapper size="small">
      <Typography.Text className="mt-0" white>
        Jūsų asmens duomenų saugumą užtikriname laikydamiesi Bendrojo duomenų
        apsaugos reglamento (ES 2016/679) reikalavimų.
      </Typography.Text>
    </PaddingWrapper>
  </>
);

export default Secure;
