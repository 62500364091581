import styled, { css } from "styled-components";
import theme from "../../config/theme";

const buttonSizeSwitcher = (size = "normal") => {
  const sizes = {
    small: {
      x: 10,
      y: 10,
    },
    normal: {
      x: 15,
      y: 15,
    },
    large: {
      x: 15,
      y: 20,
    },
  };

  return css`
    padding: ${sizes[size].y}px ${sizes[size].x}px;
  `;
};

const Button = styled.button`
  display: block;
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  border: none;
  font-size: 16px;
  font-weight: 500;
  position: relative;
  transition: 0.25s;
  background: #4575f2;

  ${({ left }) =>
    left &&
    css`
      text-align: left;
    `}

  ${({ right }) =>
    right &&
    css`
      text-align: right;
    `}

  ${({ size }) => buttonSizeSwitcher(size)}

  &:hover {
    background: #608cff;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    color: #94b1ff !important;
    background: #f3f5fe !important;
    cursor: not-allowed !important;
  }

  ${({ secondary }) =>
    secondary &&
    css`
      background: white;
      border: 1px solid ${theme.colors.blueLight};
      color: ${theme.colors.blue};

      &:hover {
        background: #f7f9ff;
      }

      &:disabled {
        color: #dadbe0 !important;
        background: #ffffff !important;
        border: 1px solid #dadbe0 !important;
        cursor: not-allowed !important;
      }
    `}

  ${({ link }) =>
    link &&
    css`
      background: none;
      border: none;
      color: #4575f2;
      text-decoration: none;

      &:hover {
        background: #f7f9ff;
        box-shadow: none;
      }

      ${({ active }) =>
        active &&
        css`
          background: #f7f9ff;
          box-shadow: none;
        `}

      &:disabled {
        color: #dadbe0;
      }
    `}
`;

export default Button;
