import React from "react";
import { Row, Col } from "react-grid-system";

const FormRow = ({ size = "medium", children }) => {
  const sizes = {
    small: {
      xs: 12,
      sm: 10,
      md: 10,
      lg: 8,
      xl: 6,
      xxl: 6,
    },
    medium: {
      xs: 12,
      sm: 12,
      md: 10,
      lg: 8,
      xl: 8,
      xxl: 8,
    },
    large: {
      xs: 12,
      sm: 12,
      md: 12,
      lg: 10,
      xl: 10,
      xxl: 10,
    },
    extraLarge: {
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12,
      xxl: 12,
    },
  };

  return (
    <Row gutterWidth={0} className="my-0">
      <Col {...sizes[size]}>{children}</Col>
    </Row>
  );
};

export default FormRow;
